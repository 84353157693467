import React from 'react'
import SidebarNavbar from '../components/dashboard/SidebarNavbar'

export default function Dashboard() {
  return (
    <div>
         <SidebarNavbar/>
    </div>
  )
}
