import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Link,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Badge,
  Button,
  Image,
  Stack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import {
  FiHome,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiUsers,
  FiUser,
  FiSettings,
  FiSearch,
  FiUserCheck,
} from 'react-icons/fi';
import {BiLogOut} from 'react-icons/bi'
import {GrGroup} from 'react-icons/gr'
import {TbReport} from 'react-icons/tb'
import logo from './logo/onemoney.png'
import logo01 from '../../images/netlogo.png'
import logo02 from '../../images/netmoney.jpg'
import netmoney from '../../images/netone.png'
import {
    Outlet, useNavigate,useLocation
  } from "react-router-dom";
import axios from '../../api/axios';
import { MdBuild, MdMyLocation, MdShop } from 'react-icons/md';
import { BsBuilding } from 'react-icons/bs';
import { FaFile, FaUserAlt, FaUsers } from 'react-icons/fa';
import { GiMoneyStack, GiTakeMyMoney } from 'react-icons/gi';
import {useAuth } from '../../context/AuthProvider';
import jwt from 'jwt-decode';

// const GETSHOPS_URL = '/employee/shop/v1/get-shops'
// const GETREGION_URL = '/employee/region/v1/get-regions';
const GETLOANPROVIDERS = '/mobiloan/loan-provider'




const LinkItems = [

  { name: 'Home', icon: FiHome,to:'/user/exadmin.dashboard', userTypes: 'EXTERNAL_USER', roles: ["ROLE_ADMIN"] },

  { name: 'Home', icon: FiHome,to:'/admin/admin.dashboard', userTypes: 'INTERNAL_USER', roles: ["ROLE_ADMIN"] },
  { name: 'Home', icon: FiHome,to:'/admin/admin.dashboard', userTypes: 'INTERNAL_USER', roles: ["ROLE_USER"] },

  { name: 'Home', icon: FiHome,to:'/user/exuser.dashboard', userTypes: 'EXTERNAL_USER', roles: ["ROLE_USER"] },

  { name: 'Loan Providers', icon: FiHome,to:'/admin/loan-provider', userTypes: 'INTERNAL_USER', roles: ["ROLE_ADMIN"] },

  { name: 'Borrowers', icon: FaUsers,to:'/user/view-borrowers-loans',userTypes: 'EXTERNAL_USER', roles: ["ROLE_ADMIN"] },
  { name: 'Borrowers', icon: FaUsers,to:'/user/view-borrowers-loans',userTypes: 'EXTERNAL_USER', roles: ["ROLE_USER"] },
  
  { name: 'Borrowers', icon: FaUsers,to:'/admin/borrower-list',userTypes: 'INTERNAL_USER', roles: ["ROLE_ADMIN"] },
  { name: 'Borrowers', icon: FaUsers,to:'/admin/borrower-list',userTypes: 'INTERNAL_USER', roles: ["ROLE_USER"] },


  { name: 'Loan Products', icon: FaFile,to:'/user/add-loan-products',userTypes: 'EXTERNAL_USER', roles: ["ROLE_ADMIN"] },

  { name: 'Users', icon: FaUserAlt,to:'/user/users', userTypes: 'EXTERNAL_USER', roles: ["ROLE_ADMIN"]},
  { name: 'Users', icon: FaUserAlt,to:'/admin/users', userTypes: 'INTERNAL_USER', roles: ["ROLE_ADMIN"]},

  // { name: 'Payments', icon: GiMoneyStack,to:'/admin/payments', roles: ["SUPER_ADMIN"] },
  // { name: 'Missed Payments', icon: GiMoneyStack,to:'/admin/missed_payments', roles: ["SUPER_ADMIN"] },

];

export default function SidebarNavbar({children}) {
  const location = useLocation();
const path = location.pathname;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [open,setOpen] = useState(false)
  const cancelRef = React.useRef()

  

  // const isAuthenticated = async () => {
  //   const tken = localStorage.getItem("token");
  //   if(tken) {
  //     const { exp } = jwt(tken)
  //     if(Date.now() <= exp * 1000) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }
  // }

//   useEffect (()=>{
//   const interval = setInterval( async()=>{
//     const tken = localStorage.getItem("token");
//     if (!tken) {
//       clear(); 
//       return;
//     }
//     const valid = await isAuthenticated()
   
//     if (!valid) {
//       setOpen(true)
//       clear();
     
//     }

//   }, 5000);

//   const clear = ()=> {
//     localStorage.clear();
//     clearInterval(interval);
//   }

//   // Clean up the interval on component unmount
//   return () => {
   
//     clearInterval(interval);
   
//   };
// }, []);
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>

     
      <SidebarContent
       path={path}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
      {/* <Statistics/> */}
        <Outlet/>
        {/* {children} */}
      </Box>
    </Box>
  );
}


const SidebarContent = ({ onClose, path, ...rest }) => {

  let user = localStorage.getItem('user')
  user = JSON.parse(user);
console.log(user)
const userRoles = user?.roles[0]
const type = user?.userType
const [open,setOpen] = useState(false)
  const cancelRef = React.useRef()


const filteredSidebarItems = LinkItems.filter(item => userRoles === item.roles[0]);
 console.log(filteredSidebarItems)

 const filterSidebarTypes = filteredSidebarItems.filter(itek => type === itek.userTypes)

const [loading, setLoading] = useState(false)



// GET REGION


const [loanprovider, setLoanProvider] = useState([])


const getLoanProviders = async () => {
    setLoading(true);
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${GETLOANPROVIDERS}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    if(response.status === 200) {
      setLoanProvider(response.data.body)
      console.log(response.data.body);
      setLoading(false);
    }
  } catch (error) {
  
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
  
     
    } else if (error.request) {
      // The request was made but no response was received
      // toast({
      //   title: "Network Error",
      //   description: "Please Check your Internet Connection",
      //   status: "error",
      //   duration: 8000,
      //   isClosable: true,
      // });
   
    } else {
      // Something happened in setting up the request that triggered an Error
      // toast({
      //   title: "Error",
      //   description: `Error:`,
      //   status: "error",
      //   duration: 8000,
      //   isClosable: true,
      // });
     
    }
  }
}

useEffect(() => {
   
      getLoanProviders();      
  }, [])
 
  const provId = user?.loanProviderId
 const providerId = loanprovider.filter(prov => provId === prov.id)
    
// console.log(providerId[0].name)
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="15" justifyContent="space-between">
       <VStack ml={-2} spacing={2}>

 <AlertDialog
        isOpen={open}
        closeOnOverlayClick={false}
        onClose={()=>{setOpen(false)}}
      >
        <AlertDialogOverlay
        bg={'blackAlpha.300'}
        backdropFilter={'blur(10px) hue-rotate(90deg)'}
        >
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            <Alert status='warning'>
           <AlertIcon />
           Attention:
            </Alert>
            </AlertDialogHeader>

            <AlertDialogBody>
            <Text>

           Your session has expired. Please log in again to continue
            </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={()=>window.location.href = "/"}  colorScheme='yellow'  ml={3}>
               Login
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
          </AlertDialogOverlay>
      </AlertDialog>

  {/* <Image
  borderRadius='full'
  boxSize='75px'
  src={logo02}
  alt='logo'
/>

  {shopes?.name ? (<Text  fontSize="sm" fontFamily="monospace" fontStyle={'oblique'}><BsBuilding />{shopes.name} </Text>) : (<Text  fontSize="sm" fontFamily="monospace" fontStyle={'oblique'}>{regiones?.name} </Text>)}
 */}

<Text  fontSize="xl" fontFamily="monospace" fontWeight={'extrabold'} fontStyle={'oblique'}>Mobiloan</Text>
            

       </VStack>
<VStack ml={-2}> 
{user?.userType === 'EXTERNAL_USER' ? (
<>
  {providerId[0]?.id === user.loanProviderId &&
  <>
  
  {providerId[0]?.name ? (<Text  fontSize="sm" fontFamily="monospace" fontStyle={'oblique'}><BsBuilding />{providerId[0].name} </Text>) : (<Text  fontSize="sm" fontFamily="monospace" fontStyle={'oblique'}>No data </Text>)}
  </>
}
  </>
):(

  <Text  fontSize="sm" fontFamily="monospace" fontStyle={'oblique'}><BsBuilding />Netone Pvt </Text>

)}

</VStack>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {filterSidebarTypes.map((link) => (
        <NavItem path={path} key={link.name} to={link.to} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}

    </Box>
  );
};

const NavItem = ({ path, icon, children,to, ...rest }) => {
  return (
    <Link href={to} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>

      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={path === to ? '#001C30' : 'white'}
        color={path === to ? 'white' : 'black'}
        _hover={{
          bg: '#176B87',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

// interface MobileProps extends FlexProps {
//   onOpen: () => void;
// }
const MobileNav = ({ onOpen, ...rest }) => {
  const navigate = useNavigate();
  let user = localStorage.getItem('user')
  user = JSON.parse(user);
  
const [loading, setLoading] = useState(false)


// GET REGION


  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      {/* {shopes?.name ? (<Text  display={{ base: 'flex', md: 'none' }}  fontSize="xl" fontFamily="monospace" fontStyle={'oblique'}><BsBuilding />{shopes.name} </Text>) : (<Text  display={{ base: 'flex', md: 'none' }} fontSize="xl" fontFamily="monospace" fontStyle={'oblique'}><MdMyLocation/> {regiones?.name} </Text>)} */}

      {/* <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        Logo
      </Text> */}

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  // src={
                  //   'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  // }
                  name={user?.firstName }
                
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="2px"
                  ml="2">
                  {/* <Text fontSize="sm">{user.firstName} {user.lastName}</Text> */}
                  {/* <Text fontSize="xs" color="gray.600">
                    {user.title}
                  </Text> */}
                  <Text fontSize="xs" color="gray.600" textTransform={'lowercase'} >
                    {/* {user.roles[0].name} */}
                    
                    {user?.roles[0] === 'ROLE_ADMIN' ? (<Badge variant='solid' colorScheme='cyan'> Admin</Badge>):user?.roles[0] === 'ROLE_USER' ? (<Badge variant='solid' colorScheme='grey'> User</Badge>):<Badge variant={'solid'} colorScheme={'linkedin'} size={'xs'}>User</Badge>}
                     
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              {/* <MenuItem icon={<FiUser/>}
              onClick={()=>navigate(`/admin/profile/${user.id}`)}>Profile</MenuItem> */}
           
              {/* <MenuDivider /> */}
              <MenuItem icon={<BiLogOut/>}
              onClick={() => {
                localStorage.clear();
                navigate("/")
              }}
              >Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};