import React from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Container,
    Flex,
    Stack,
    Heading,
  } from '@chakra-ui/react'

function ReceivedPayment() {
  return (
    <>
    <Heading fontSize={'xl'} m={8}>List of Payments</Heading>
    <Stack bg={'white'} m={10} boxShadow="lg"  borderRadius="sm">
        <TableContainer  >
    <Table size='md'>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Loan ID</Th>
          <Th>Customer Name</Th>
          <Th>Phone</Th>
          <Th>Loan Product</Th>
          <Th>Amount Paid</Th>
          <Th>Date</Th>
          <Th>Balance</Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
         <Td>1</Td>
         <Td>1234</Td>
         <Td>Noah Banda</Td>
         <Td>0712980481</Td>
         <Td>School Loan</Td>
         <Td>$200</Td>
         <Td>01/01/2023</Td>
         <Td>$100000</Td>
        </Tr>
        <Tr>
        <Td>1</Td>
         <Td>1234</Td>
         <Td>Noah Banda</Td>
         <Td>0712980481</Td>
         <Td>School Loan</Td>
         <Td color={'green'} fontWeight={'semibold'}> $200</Td>
         <Td>01/01/2023</Td>
         <Td color={'red'} fontWeight={'semibold'}>$500000</Td>
        </Tr>
      </Tbody>
    </Table>
  </TableContainer>
  </Stack>
  </>
  )
}

export default ReceivedPayment