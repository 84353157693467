import { 
    Box,
    Flex, 
    Heading, 
    useColorModeValue,
    Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useToast,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Select,
  Button,
  Badge,
  Wrap,
  Image,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  IconButton,
  InputRightElement,
  InputGroup,
  HStack,
  Stack,
  StackDivider,
  Text,
  Spacer,
  Center,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
 } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Borrower from '../../borrowers/Borrower';
import axios from '../../../api/axios';
import { MdArrowBack, MdUpdate } from 'react-icons/md';

const GETBORROWERS_BY_ID = '/mobiloan/borrowers/loan-provider'
const GETCURRENCY_URL = '/mobiloan/currency'
const ADDLOANPRODUCTURL = '/mobiloan/loan-products/create/'
const UPDATEPRODUCT_URl = '/mobiloan/loan-products/edit'
const UPDATELOANPROVIDER_URL = '/mobiloan/loan-provider/'
const GETLOANPROVIDERS = '/mobiloan/loan-provider'


function ViewProvider() {
    const location = useLocation();
const [details, setDetails] = useState(location.state)
const [currentSelected,setCurrentSelected] = useState(null)
console.log(details)

const [borrower, setBorrower] = useState([])
const [name, setName] = useState('');
const [minLoanAmount, setMinLoanAmount] = useState('');
const [maxLoanAmount, setMaxLoanAmount] = useState('');
const [flatInterestRate, setFlatInterestRate] = useState('');
const [monthsToPay, setMonthsTopay] =useState('');
const [penaltyRate, setPenaltyrate] =useState('');
const [gracePeriodDays, setGraceperiodDays] = useState('');
const [selectCurrency, setSelectCurrency] = useState([])
const [currency, setCurrency] = useState('')

// const [providername, setProviderName] = useState('');
//     const [providerShortCode, setProviderShortCode] = useState('');
//     const [ providerUsername, setProviderUsername] = useState('')
//     const [providerUserPassword, setProviderUserPassword ] = useState('');

const [loading,setLoading] = useState(false);
const toast = useToast();
const navigate = useNavigate();
const { isOpen, onOpen, onClose} =useDisclosure();
const initialRef = React.useRef(null);

const {isOpen: isLoanOpen, onOpen: onLoanOpen, onClose: onLoanClose} = useDisclosure();
const myRef = React.useRef(null);
//Modal to edit loan product
const {isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose} = useDisclosure();
const editRef = React.useRef(null);

const {isOpen: isViewOpen, onOpen: onViewOpen, onClose: onViewClose} = useDisclosure();
const viewRef = React.useRef(null);



const [show, setShow] = React.useState(false);
const handleClick = () => setShow(!show);

let user = localStorage.getItem('user')
      user = JSON.parse(user);
      console.log(user)
      

const getBorrowers = async () => {
  setLoading(true);
const token = localStorage.getItem("token");
try {
  const response = await axios.get(`${GETBORROWERS_BY_ID}/${user.id}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  if(response.status === 200) {
    setBorrower(response.data.body)
    console.log(response.data.body);
    setLoading(false);
  }
} catch (error) {

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    toast({
      title: "Error",
      description: `Server responded with ${error.message}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
   
  } else if (error.request) {
    // The request was made but no response was received
    toast({
      title: "Network Error",
      description: "Please Check your Internet Connection",
      status: "error",
      duration: 8000,
      isClosable: true,
    });
 
  } else {
    // Something happened in setting up the request that triggered an Error
    toast({
      title: "Error",
      description: `Error:`,
      status: "error",
      duration: 8000,
      isClosable: true,
    });
   
  }
}
}

useEffect(() => {
 
    getBorrowers();      
}, [])

const getCurrencies = async () => {
  setLoading(true);
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${GETCURRENCY_URL}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    if(response.status === 200) {
      setSelectCurrency(response.data.body)
      setLoading(false);
    }
  } catch (error) {

  }
}

useEffect(() => {
   
    getCurrencies();
  
  }, [])

  const handleAddLoanProduct = () => {
      //     setErrorMessage('')
      // const res = validate();
      //     if (!res) {
      //   console.log('validation failed')
      //   toast({
      //     title: 'Failed',
      //     description: "Form not complete",
      //     status: 'error',
      //     duration: 3000,
  
      //   });
      //   return;
      // }
          setLoading(true)
          const token = localStorage.getItem("token");
          axios.post(ADDLOANPRODUCTURL + `${user.id}`, {
            name,
            minLoanAmount,
            maxLoanAmount,
            flatInterestRate,
            monthsToPay,
            penaltyRate,
            gracePeriodDays,
            currency,
          },{
            headers: { 
              "Content-type": "application/json",
              'Authorization': `Bearer ${token}`
            }
            
          })
          .then(response => {
              // console.log(response)
              if (response.data.success) {
                  navigate('/admin/loan-provider')
                  onClose();
                  toast({
                    title: 'Success',
                    description: response.data.message,
                    status: 'success',
                    duration: 6000,
                    
                  });
                  setLoading(false)
                }
              
              else {
                setLoading(false)
                toast({
                  title: 'Failed',
                  description: response.message,
                  status: 'error',
                  duration: 3000,
                 
                });
              }
          })
          .catch(error => {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                toast({
                  title: "Error",
                  description: `Server responded with ${error.response.status}`,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
                setLoading(false)
              } else if (error.request) {
                // The request was made but no response was received
                toast({
                  title: "Network Error",
                  description: "No response from server",
                  status: "error",
                  duration: 8000,
                  isClosable: true,
                });
                setLoading(false)
              } else {
                // Something happened in setting up the request that triggered an Error
                toast({
                  title: "Network Error",
                  description: `Error: ${error.message}`,
                  status: "error",
                  duration: 8000,
                  isClosable: true,
                });
               
              }
            })
      }

    //   const inititate = {
    //     name: "",
    //     minLoanAmount: "",
    //     maxLoanAmount:"",
    //     flatInterestRate:"",
    //     monthsToPay:"",
    //     penaltyRate: "",
    //     gracePeriodDays:"",
    //     currency: ""
    //   }
    //   console.log(details)
    // const [loanP , setLoanP] = useState(inititate);

    // const {name, minLoanAmount, maxLoanAmount, flatInterestRate, monthsToPay, penaltyRate, gracePeriodDays, currency}= loanP
    // const handleInputChange = (e) => {
    //   const { name, value } = e.target;
    //   setLoanP({ ...loanP, [name]: value });
    // };
const [editname, setEditName] = useState('');

    const handleEditProduct = () => {
      setLoading(true);
      const token = localStorage.getItem("token");
     const loanProductId = currentSelected.id
      axios.post(`${UPDATEPRODUCT_URl}/${loanProductId}`, {
        name,
        minLoanAmount,
        maxLoanAmount,
        flatInterestRate,
        monthsToPay,
        penaltyRate,
        gracePeriodDays,
        currency,
  
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
  
      })
  
  
        .then(response => {
          if (response.data.success) {
            navigate('/admin/loan-provider')
            onEditClose()
            setLoading(false)
            toast({
              title: 'Loan Product Updated',
              description: response.data.message,
              status: 'success',
              duration: 6000,
  
            });
          }
  
          else {
            setLoading(false)
            toast({
              title: 'Failed',
              description: response.data.message,
              status: 'error',
              duration: 3000,
  
            });
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Error",
              description: `Server responded with ${error.response.status}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
  
          }
        })
    }

    // const inititateProvider = {
    //   providername:details.name,
    //   providerShortCode: details.providerShortCode,
    //   providerUsername: details.providerUsername,
    //   providerUserPassword: details.providerUserPassword
    // }
    // console.log()
    // const [myprovider, setMyProvider] = useState(inititateProvider)

    // const {providername, providerShortCode, providerUsername, providerUserPassword} = myprovider
    // const handleLoanProvider = (e) => {
    //   const { name, value } = e.target;
    //   setMyProvider({ ...myprovider, [name]: value });
    // };

    // const handlesubmit = () => {

    //       setLoading(true)
    //       const token = localStorage.getItem("token");
    //       axios.put(UPDATELOANPROVIDER_URL + `${user.id}`, {
    //         name: myprovider.providername,
    //         providerShortCode: myprovider.providerShortCode,
    //         providerUsername: myprovider.providerUsername,
    //         providerUserPassword: myprovider.providerUserPassword
  
            
    //       },{
    //         headers: { 
    //           "Content-type": "application/json",
    //           'Authorization': `Bearer ${token}`
    //         }
            
    //       })
    //       .then(response => {
    //           // console.log(response)
    //           if (response.data.success) {
    //               navigate('/admin/loan-provider')
    //               onLoanClose();
    //               toast({
    //                 title: 'Success',
    //                 description: response.data.message,
    //                 status: 'success',
    //                 duration: 6000,
                    
    //               });
    //               setLoading(false)
    //             }
              
    //           else {
    //             setLoading(false)
    //             toast({
    //               title: 'Failed',
    //               description: response.message,
    //               status: 'error',
    //               duration: 3000,
                 
    //             });
    //           }
    //       })
    //       .catch(error => {
    //           if (error.response) {
    //             // The request was made and the server responded with a status code
    //             // that falls out of the range of 2xx
    //             toast({
    //               title: "Error",
    //               description: `Server responded with ${error.response.status}`,
    //               status: "error",
    //               duration: 5000,
    //               isClosable: true,
    //             });
    //             setLoading(false)
    //           } else if (error.request) {
    //             // The request was made but no response was received
    //             toast({
    //               title: "Network Error",
    //               description: "No response from server",
    //               status: "error",
    //               duration: 8000,
    //               isClosable: true,
    //             });
    //             setLoading(false)
    //           } else {
    //             // Something happened in setting up the request that triggered an Error
    //             toast({
    //               title: "Network Error",
    //               description: `Error: ${error.message}`,
    //               status: "error",
    //               duration: 8000,
    //               isClosable: true,
    //             });
               
    //           }
    //         })
    //   }

    
        const property = {
          imageUrl: 'https://bit.ly/2Z4KKcF',
          imageAlt: 'Rear view of modern home with pool',
        }

  return (
    <>
           <Breadcrumb ml={3} mb={2} fontWeight='medium' fontSize='sm'>
  <BreadcrumbItem>
    <BreadcrumbLink href='/admin/super-home'>Home</BreadcrumbLink>
  </BreadcrumbItem>
  <BreadcrumbItem>
    <BreadcrumbLink href='/admin/loan-provider'>Loan Providers</BreadcrumbLink>
  </BreadcrumbItem>

  <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='/admin/user-management.app'>Loan Provider Profile</BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>
    <Box
        maxW="full" 
        mx={'2'}
        px={{ base: 2, md: 4 }}
        py={'5'} 
        shadow={'md'}
        border={'2px solid'}
        bgColor={useColorModeValue('gray.50')}
        borderColor={useColorModeValue('gray.50', 'gray.50')}
        overflowX={'scroll'}
        >
<Center>


{/* <Box  
maxW={'6xl'}
mb={4}
p={2}
  shadow={'md'}
  border={'6px solid'}
  bgColor={useColorModeValue('gray.50')}
  borderColor={useColorModeValue('gray.50', 'gray.50')}
  rounded={'lg'}
  maxH={'25vh'}
 
>
<Heading textTransform={'capitalize'} lineHeight={1.1} fontWeight={'bold'} fontSize={'lg'}>Company Profile</Heading>

<Flex justify={'flex-start'} gap={'250'}>
    <Box>
<Text  mt={4} mb={8} ml={4} textTransform={'capitalize'} lineHeight={1.1} fontWeight={'semibold'}  fontSize={{ base: '3xl', sm: '3xl' }} >{details?.name}</Text>

                
                  <StatGroup ml={4} gap={'12'}>
            <Stat>
                <StatLabel>Username:</StatLabel>
                <StatNumber>{details?.providerUsername}</StatNumber>
                
            </Stat>

            <Stat>
                <StatLabel>Shortcode:</StatLabel>
                <StatNumber>{details?.providerShortCode}</StatNumber>
                
            </Stat>
</StatGroup>
                
    </Box>
    <Box>
    <Image mt={-6} boxSize='150px' objectFit={'cover'} rounded={'lg'} src={property.imageUrl} alt={property.imageAlt} />

    </Box>
</Flex>

</Box> */}
</Center>
 <Flex justify={'flex-start'} mt={4}>
<Box  

p={2}
  shadow={'2xl'}
  border={'6px solid'}
  bgColor={useColorModeValue('gray.50')}
  borderColor={useColorModeValue('gray.50', 'gray.50')}
  rounded={'lg'}
  mr={6}
  w={'1000px'}
>
<Heading fontSize={'2xl'} mt={2} ml={4} mb={2}>Loan Products</Heading>
<TableContainer  maxW={'100vw'} overflowX={'scroll'} bg={'white'} rounded={'lg'} mt={8} p={3}>
              {details?.loanProducts?.length > 0 ? (
  <Table size='lg' variant={'simple'}>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Name</Th>
        <Th>Interest(%)</Th>
        <Th>Currency</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    
      {details?.loanProducts.map((data, index) => {
          let calculations;
          calculations = data.flatInterestRate * 100;
          return(
          <Tr key={index}>
          <Td>{index + 1}</Td>
          <Td>{data.name}</Td>
          <Td>{calculations}%</Td>
          <Td>{data.currency.name}</Td>
          <Td>
          <Wrap spacing={2} direction='row' align='center'>
              <Button onClick={()=> {
                onViewOpen()
                setCurrentSelected({data})
                setName(data.name)
                setMinLoanAmount(data.minLoanAmount)
                setMaxLoanAmount(data.maxLoanAmount)
                setFlatInterestRate(data.flatInterestRate)
                setMonthsTopay(data.monthsToPay)
                setPenaltyrate(data.penaltyRate)
                setGraceperiodDays(data.gracePeriodDays)
                setCurrency(data.currency)
              }} bg={'#001C30'} color={'white'} size='xs'>View</Button>
              <Modal
      initialFocusRef={viewRef}
      isOpen={isViewOpen}
      onClose={onViewClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg={'#001C30'} color={'white'}> View Loan Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
        <Flex justifyContent={'space-evenly'}>
            <Stack  divider={<StackDivider />} spacing={4} justifyContent={'space-evenly'} alignContent={'space-evenly'}>
              <HStack spacing={14}>

                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Product Name:
                  </Heading>
                  <FormControl mt={2} isReadOnly>

                  <Input variant={'unstyled'} value={name} size={'lg'}
                    />
                </FormControl>
                </Box>
                
              </HStack>
              <HStack spacing={8}>
              <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Min Loan Amount: $
                  </Heading>
                  
                  <FormControl mt={2} isReadOnly>
                  
                  <Input variant={'unstyled'}  value={minLoanAmount} 
                    />
                  </FormControl>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Max Loan Amount: $
                  </Heading>
                  <FormControl mt={2} isReadOnly>

                  <Input variant={'unstyled'} value={maxLoanAmount} 
                    />
                </FormControl>
                </Box>
                
              </HStack>
              <HStack spacing={8}>
              <Box>
                <Heading size='xs' textTransform='capitalize'>
                  Payback Period: Months
                </Heading>
                <FormControl mt={2} isReadOnly>

                <Input variant={'unstyled'}  value={monthsToPay} 
                  />
                </FormControl>
              
              </Box>
              <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Interest Rate: %
                  </Heading>
                  <FormControl mt={2} isReadOnly>

                  <Input variant={'unstyled'} value={calculations} 
                    />
                  </FormControl>
                </Box>
                </HStack>
              <HStack spacing={8}>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Penalty Rate: %
                  </Heading>
                  <FormControl mt={2} isReadOnly>
                  <Input variant={'unstyled'}  value={penaltyRate} 
                    />
                  </FormControl>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Grace Period: Days
                  </Heading>
                  <FormControl mt={2} isReadOnly>

                  <Input variant={'unstyled'}  value={gracePeriodDays} 
                    />
                  </FormControl>
                </Box>
              </HStack>
              <HStack spacing={20}>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Currency:
                  </Heading>
                  <FormControl mt={2} isReadOnly>

                <Input variant={'unstyled'} value={currency.name} 
                  />
                </FormControl>
                </Box>
                
              </HStack>

            </Stack>

              </Flex>
        
         
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='red' onClick={onViewClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>  


              <Button onClick={()=>{
                onEditOpen()
                setCurrentSelected(data)
                setName(data.name)
                setMinLoanAmount(data.minLoanAmount)
                setMaxLoanAmount(data.maxLoanAmount)
                setFlatInterestRate(data.flatInterestRate)
                setMonthsTopay(data.monthsToPay)
                setPenaltyrate(data.penaltyRate)
                setGraceperiodDays(data.gracePeriodDays)
                setCurrency(data.currency)
              }} colorScheme='yellow' color={'white'} size='xs'>Edit</Button>

              <Modal
      initialFocusRef={editRef}
      isOpen={isEditOpen}
      onClose={onEditClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg={'yellow.400'} color={'white'}>Update Loan Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
        <HStack spacing={4}>

<FormControl>
<FormLabel>Product Name:</FormLabel>
<Input type={'text'} placeholder='name' value={name} onChange={(e)=> setName(e.target.value)}  
/>
</FormControl>

</HStack>
<HStack mt={2} spacing={4}>

<FormControl>
<FormLabel>Minimum Loan Amount</FormLabel>
<Input type={'number'} placeholder='0,00' name='minLoanAmount'  value={minLoanAmount} onChange={(e)=> setMinLoanAmount(e.target.value)}  
/>
</FormControl>
<FormControl >
<FormLabel>Maximum Loan Amount:</FormLabel>
<Input type={'number'} placeholder='0,00' name='maxLoanAmount' value={maxLoanAmount} onChange={(e)=> setMaxLoanAmount(e.target.value)}  
/>
</FormControl>
</HStack>

<FormControl>
<FormLabel>Interest Rate</FormLabel>
<NumberInput onChange={(e)=>setFlatInterestRate(e)} defaultValue={flatInterestRate} w={'195px'}  precision={1} step={0.1} >
  <NumberInputField />
  <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
  </NumberInputStepper>
</NumberInput>
</FormControl>
<HStack mt={2} spacing={4}>
<FormControl>
<FormLabel>Loan Period</FormLabel>
<Select onChange={(e)=>setMonthsTopay(e.target.value)} name='monthsToPay' value={monthsToPay} borderColor='red' 
        _hover={{
        borderRadius: 'red',
    }} 
    placeholder='Select number'>
  <option value='1'>1</option>
  <option value='2'>2</option>
  <option value='3'>3</option>
  <option value='4'>4</option>
  <option value='5'>5</option>
 
</Select>
</FormControl>
<FormControl>
<FormLabel>Penalty Rate</FormLabel>
<NumberInput onChange={(e)=>setPenaltyrate(e)} defaultValue={penaltyRate} w={'195px'}  precision={1} step={0.1} >
  <NumberInputField />
  <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
  </NumberInputStepper>
</NumberInput>
</FormControl>
</HStack>
<FormControl>
<FormLabel>Set Grace Period</FormLabel>
<Input type={'number'} placeholder='0' value={gracePeriodDays} onChange={(e)=>setGraceperiodDays(e.target.value)}  
/>
</FormControl>

<FormControl mt={2}>
<FormLabel>Currency</FormLabel>
 <Select 
        _hover={{
          borderRadius: 'orange',
        }} 
        placeholder='Update Currency' w={'195px'}  value={currency.id} onChange={(e)=>setCurrency(e.target.value)}>
   {selectCurrency.length === 0 && <Spinner />}
{selectCurrency.map((currency) => (
  <option key={currency.id} value={currency.id}>
    {currency.name}
  </option>
))} 
</Select>
</FormControl>
        
          {/* {
            errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
          } */}
        </ModalBody>

        <ModalFooter>
          <Button 
         onClick={handleEditProduct} 
         isLoading={loading}
         loadingText='Loading'
         spinnerPlacement='start'
         bg={'#001C30'}
         color={'white'}
          mr={3}>
           Update
         </Button>
          <Button colorScheme='red' onClick={onEditClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>  
              {/* <Button onClick={() => navigate(`/admin/edit-cust`,{
                  state:data
              })} colorScheme='yellow' size='xs'>Edit</Button> */}
              
              {/* <Button colorScheme='red' size='xs'>Delete</Button> */}
              </Wrap>

          </Td>
          </Tr>


          )
      })}
    </Tbody>
  </Table>
  ): (
      <Table>
          <TableCaption>
              <VStack spacing={2}>

              <Text>No Data to Display</Text>
              <Button
          onClick={onOpen}
            mt={8}
            bg={'blue.400'}
            color={'white'}
            w="150px"
            _hover={{
              bg: 'teal.500',
            }}>
            Add Loan Product
          </Button>
              </VStack>

              </TableCaption>
    <Tr>
    </Tr>
      
      </Table>
        )}
</TableContainer>

{/* modal to add loan product */}
<Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Loan Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <HStack spacing={4}>

          <FormControl>
          <FormLabel>Product Name:</FormLabel>
          <Input type={'text'} placeholder='name'  onChange={(e)=> setName(e.target.value)}  
          />
        </FormControl>
         
          </HStack>
          <HStack mt={2} spacing={4}>

        <FormControl>
          <FormLabel>Minimum Loan Amount</FormLabel>
          <Input type={'number'} placeholder='$0,00'  onChange={(e)=>setMinLoanAmount(e.target.value)}  
          />
        </FormControl>
        <FormControl >
          <FormLabel>Maximum Loan Amount:</FormLabel>
          <Input type={'number'} placeholder='$0,00'  onChange={(e)=>setMaxLoanAmount(e.target.value)}  
          />
        </FormControl>
        </HStack>
       
        <FormControl>
          <FormLabel>Interest Rate</FormLabel>
          <NumberInput onChange={(e)=>setFlatInterestRate(e)} defaultValue={0} w={'195px'}  precision={1} step={0.1} >
              <NumberInputField />
              <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
              </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <HStack mt={2} spacing={4}>
        <FormControl>
          <FormLabel>Loan Period</FormLabel>
        <Select onChange={(e)=>setMonthsTopay(e.target.value)} borderColor='red' 
                    _hover={{
                    borderRadius: 'red',
                }} 
                placeholder='Select number'>
              <option name='monthsToPay' value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
             
            </Select>
            </FormControl>
            <FormControl>
          <FormLabel>Penalty Rate</FormLabel>
          <NumberInput onChange={(e)=>setPenaltyrate(e)} defaultValue={0} w={'195px'}  precision={1} step={0.1} >
              <NumberInputField />
              <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
              </NumberInputStepper>
          </NumberInput>
        </FormControl>
          </HStack>
          <FormControl>
          <FormLabel>Set Grace Period</FormLabel>
          <Input type={'number'} placeholder='0' name={'gracePeriodDays'} onChange={(e)=>setGraceperiodDays(e.target.value)}  
          />
        </FormControl>
        
        <FormControl mt={2}>
        <FormLabel>Currency</FormLabel>
             <Select 
                    _hover={{
                      borderRadius: 'orange',
                    }} 
                    placeholder='Select Currency' w={'195px'}  onChange={(e)=>setCurrency(e.target.value)}>
               {selectCurrency.length === 0 && <Spinner />}
            {selectCurrency.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.name}
              </option>
            ))} 
            </Select>
        </FormControl>
        
          {/* {
            errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
          } */}
        </ModalBody>

        <ModalFooter>
          <Button 
         onClick={handleAddLoanProduct} 
         isLoading={loading}
         loadingText='Loading'
         spinnerPlacement='start'
         bg={'#001C30'}
         color={'white'}
          mr={3}>
           Save
         </Button>
          <Button colorScheme='red' onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>            

<Stack mt={2} align={'center'} justify={'center'} spacing={2} direction={['row']}>
          <Button
          onClick={onLoanOpen}
            rightIcon={<MdUpdate/>}
            bg={'yellow.500'}
            color={'white'}
            w="150px"
            _hover={{
              bg: 'blue.500',
            }}>
            Update 
          </Button>
          {details?.loanProducts?.length > 0 ? (
          <Button
          onClick={onOpen}
            mt={8}
            bg={'#001C30'}
            color={'white'}
            w="150px"
            _hover={{
              bg: 'teal.500',
            }}>
            Add Loan Product
          </Button>
          
           ): (
              
                      <></>
            
                )}
        </Stack>
</Box>
<Spacer />
<Box  
p={2}
  shadow={'xl'}
  border={'6px solid'}
  bgColor={useColorModeValue('gray.50')}
  borderColor={useColorModeValue('gray.50', 'gray.50')}
  rounded={'lg'}
 
>
    <Heading fontSize={'2xl'} mt={2} ml={4} mb={2}>List of Borrowers</Heading>
    <TableContainer maxW={'100vw'} overflowX={'scroll'} bg={'white'} rounded={'lg'} mt={2} p={3} >
                 {borrower?.length > 0 ? (
  <Table size='lg'>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Full Name</Th>
        <Th>National ID</Th>
        <Th>Phone number</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
      {borrower.map((data, index) => {
          return(
          <Tr key={index}>
          <Td>{index + 1}</Td>
          <Td>{`${data.firstName} ${data.lastName}`}</Td>
          <Td>{data.nationalIdNumber}</Td>
          <Td>{data.phoneNumber}</Td>
          <Td>
          <Wrap spacing={2} direction='row' align='center'>
              <Button onClick={() => navigate(`/admin/view-loans`,{
            state:data
          })}  bg={'#001C30'} color={'white'} size='xs'>View loans</Button>
              {/* <Button onClick={() => navigate(`/admin/edit-cust`,{
                  state:data
              })} colorScheme='yellow' size='xs'>Edit</Button> */}
              
              {/* <Button colorScheme='red' size='xs'>Delete</Button> */}
              </Wrap>

          </Td>
          </Tr>


          )
      })}
    </Tbody>
  </Table>
): (
  <Table>
      <TableCaption><Text>No Data to Display</Text></TableCaption>
<Tr>
</Tr>
  
  </Table>
    )}

</TableContainer>


          
</Box>
            </Flex>

    </Box>
    </>
  )
}

export default ViewProvider