import { Box, Center, Heading,Text, Flex, Button } from '@chakra-ui/react';
import { BsCheckCircleFill, BsFillCheckCircleFill } from 'react-icons/bs';
import c2 from '../../images/c2.png'

export default function EmailSuccess() {
  return (
    <Box textAlign="center" py={10} px={6}>
        <Flex m={2}  justifyContent={'center'} >

<img src={c2}
      alt='logo'
      size="full"
      position="relative"
      display='block'
      width='10%'
      height='10%'
      justify={'center'}
      margin-left='100%'
      />
      </Flex>
        <Center>
        <BsFillCheckCircleFill  size={'50px'} color={'green'} />
        </Center>

     
      <Heading as="h2" size="xl" mt={6} mb={2}>
       Email Verification
      </Heading>
      <Text color={'gray.500'}>
        A link was sent to your email account to reset your password.
      </Text>
     
    </Box>
  );
}