
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Container,
    Flex,
    Stack,
    Heading,
    Wrap,
    Button,
    useToast,
    useDisclosure,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Input,
    Box
  } from '@chakra-ui/react'
  import axios from '../../api/axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { read, utils, writeFile } from 'xlsx'
import { MdDelete, MdEdit, MdPreview } from 'react-icons/md';


  const GETBORROWERS_BY_ID = '/mobiloan/borrowers/loan-provider'

  

function Borrower() {
    const [borrower, setBorrower] = useState([])
    const [loading,setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const [open,setOpen] = useState(false)
    const [currentData,setCurrentData] = useState()


    const getBorrowers = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETBORROWERS_BY_ID}/64abc0c6c162505f41aa63a3`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setBorrower(response.data.body)
          console.log(response.data.body);
          setLoading(false);
        }
      } catch (error) {
      
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
         
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
       
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Error",
            description: `Error:`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });
         
        }
      }
    }
  

    useEffect(() => {
       
          getBorrowers();      
      }, [])

      const handleExport = () => {
        const headings = [[ "#","FullName", "NationalID","Phone Number","KYC Check Result", "Amount($)", "Reasons"]];
        const wb = utils.book_new();
        const ws = utils.table_to_sheet(document.getElementById('customers'));
        utils.sheet_add_aoa(ws, headings);
     //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
        utils.book_append_sheet(wb, ws, "ValidatedData_Report");
        writeFile(wb, "ValidatedData_Report.xlsx")
         };
  
         const validate = () =>{
           
         }
         const [filters, setFilters] = useState({
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          phoneNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        //   email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        //   "role.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        
        });

         /* Table Header */
         const [globalFilterValue, setGlobalFilterValue] = useState("");
         const onGlobalFilterChange = (e) => {
           const value = e.target.value;
           let _filters = { ...filters };
       
           _filters["global"].value = value;
       
           setFilters(_filters);
           setGlobalFilterValue(value);
         };
         const renderHeader = () => {
           
           return (
             <div className="flex justify-content-end">
               <span className="p-input-icon-left">
                 <Input
                   value={globalFilterValue}
                   onChange={onGlobalFilterChange}
                   placeholder="Keyword Search"
                 />
               </span>
             </div>
           );
         };
       
       //   const dateBodyTemplate = (rowData) => {
       //     return formatDate(rowData.dateOfBirth);
         
       //   };
         
       
       //   const dateFilterTemplate = (options) => {
       //     return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
       //   };
   
       const actionBodyTemplate = (rowData) => {
         return (
             <React.Fragment>
                 <Wrap spacing={2} direction='row' align='center'>
                 <Button onClick={() => navigate(`/admin/view-borrowers-loans`,{
              state:rowData
            })}  bg={'#001C30'} color={'white'} size='xs'>View Loans</Button>
                
                    </Wrap>
             </React.Fragment>
         );
     };
       
         const header = renderHeader();
         /* End of Table Header */

      

  return (
    <>
  <Container maxW={'60xl'}>

<Breadcrumb ml={3} mb={2} fontWeight='medium' fontSize='sm'>
  <BreadcrumbItem>
    <BreadcrumbLink href='/admin/home.app'>Home</BreadcrumbLink>
  </BreadcrumbItem>

  <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='/admin/reporting.app'>Borrowers</BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>
        {/* <Button
                leftIcon={<BiUserPlus/>}
                onClick={onOpen} 
                bg={'#EDEBCA'}
                color={'black'}
                _hover={{
                    bg: '#2C2727',
                    color: 'white',
                }}
                mt={2}
                ml={2}
                variant='solid'
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                rounded={'md'}
                w={'150px'}>
                    Create User
                </Button> */}

    <Box
    bg={'white'}
    borderWidth="1px"
    rounded="lg"
    shadow="1px 1px 3px rgba(0,0,0,0.3)"
    p={6}
    m="10px auto">
     
    <>
    <DataTable
      id="id"
        value={borrower}
        header={header}
        paginator
        rows={10}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        filters={filters}
        showGridlines
        filterDisplay="row"
        loading={loading}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        globalFilterFields={[
          "firstName",
          "lastName",
          "phoneNumber",
          
        ]}
        emptyMessage="No employees found.">
        <Column
          field="firstName"
          header="FirstName"
          sortable
          filter
          filterPlaceholder="Search by firstName"
          style={{ minWidth: "12rem" }}></Column>
        <Column
          field="lastName"
          header="LastName"
          sortable
          filter
          filterField="lastname"
          filterPlaceholder="Search by lastname"
          style={{ minWidth: "12rem" }}></Column>
          
          
        <Column
          field="phoneNumber"
          header="phone Number"
          sortable
          filter
          filterField="phonenumber"
          filterPlaceholder="Search by phoneNumber"
          style={{ minWidth: "12rem" }}></Column>

 <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem'  }}></Column>

    
      </DataTable>
</>
 
 
      </Box>
        </Container>




    {/* <Heading fontSize={'xl'} m={8}>List of Borrowers</Heading>
   
    <Stack bg={'white'} m={10} boxShadow="lg"  borderRadius="sm">
        <TableContainer  >
    <Table size='md'>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Full Name</Th>
          <Th>Phone number</Th>
          <Th>Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {borrower.map((data, index) => {
            return(
            <Tr key={index}>
            <Td>{index + 1}</Td>
            <Td>{`${data.firstName} ${data.lastName}`}</Td>
            <Td>{data.phoneNumber}</Td>
            <Td>
            <Wrap spacing={2} direction='row' align='center'>
                <Button onClick={() => navigate(`/admin/view-loans`,{
              state:data
            })}  bg={'#001C30'} color={'white'} size='xs'>View Loans</Button>
              
                </Wrap>

            </Td>
            </Tr>


            )
        })}
      </Tbody>
    </Table>
  </TableContainer>
  </Stack> */}

  </>


  )
}

export default Borrower