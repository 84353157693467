
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
// import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import { Box, ButtonGroup, Heading, Text,Button, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { MdOutlineExpand, MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';

const GETBORROWERS_BY_ID = '/mobiloan/borrowers/loan-provider'


export default function AddBorrowers() {
    const [products, setProducts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const [loading,setLoading] = useState(false);
    // const toast = useToast();
    const navigate = useNavigate();

    let user = localStorage.getItem('user')
      user = JSON.parse(user);

    
    const getBorrowers = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETBORROWERS_BY_ID}/${user.loanProviderId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setProducts(response.data.body)
          console.log(response.data.body);
          setLoading(false);
        }
      } catch (error) {
      
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
         
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
       
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Error",
            description: `Error:`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });
         
        }
      }
    }
  

    useEffect(() => {
       
          getBorrowers();      
      }, [])

    // useEffect(() => {
    //     ProductService.getProductsWithOrdersSmall().then((data) => setProducts(data));
    // }, []); 

    const onRowExpand = (event) => {
        toast.current.show({ severity: 'info', summary: 'Loan Expanded', detail: event.data.firstName, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: 'success', summary: 'Loan Collapsed', detail: event.data.firstName, life: 3000 });
    };

    const expandAll = () => {
        let _expandedRows = {};

        products.forEach((p) => (_expandedRows[`${p.id}`] = true));

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const amountBodyTemplate = (rowData) => {
        return formatCurrency(rowData.amount);
    };

    const formatDate = (value) => {
        const date = new Date(value)
        return date.toLocaleDateString( 'en-US', {
            month: '2-digit',
            day: '2-digit',
          year: 'numeric'
        })
    //     const date = new Date(value);
    //    return  date.toLocaleDateString();
        // const formattedTime = date.toLocaleTimeString();
    };
    
    const dateLoanBodyTemplate = (rowData) => {
        return formatDate(rowData.loanDueDate);
        
    };
    
    const loanBodyTemplate = (rowData) =>{
        return formatDate(rowData.loanDate);
    }
    
        const dateBodyTemplate = (rowData) => {
            return formatDate(rowData.paymentDueDate);
          
          };

      const paymentBodyTemplate = (rowData) =>{
        return formatDate(rowData.paymentDate)
      }
    

    const statusOrderBodyTemplate = (rowData) => {
        return <Tag  severity={getOrderSeverity(rowData)}>

            
            { rowData.stage === "PROCESSED" ? 'Successfull': rowData.stage === 'PROCESSING' ? 'Pending' : rowData.stage === 'PROCESS_FAILED'? 'Failed' : ''

            }
        </Tag>;
    };

    const searchBodyTemplate = () => {
        return <Button icon="pi pi-search" />;
    };

    // const imageBodyTemplate = (rowData) => {
    //     return <img src={`https://primefaces.org/cdn/primereact/images/product/${rowData.image}`} alt={rowData.image} width="64px" className="shadow-4" />;
    // };

    // const priceBodyTemplate = (rowData) => {
    //     return formatCurrency(rowData.price);
    // };

    const ratingBodyTemplate = (rowData) => {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.paymentStatus} severity={getProductSeverity(rowData)}></Tag>;
    };

    const getProductSeverity = (product) => {
        switch (product.paymentStatus) {
            case 'PAID':
                return 'success';


            case 'UNPAID':
                return 'danger';

            default:
                return null;
        }
    };

    const getOrderSeverity = (product) => {
        switch (product.stage) {
            case 'PROCESSED':
                return 'success';

            case 'PROCESSING':
            return 'warning';

            case 'PROCESS_FAILED':
                return 'danger';

            default:
                return null;
        }
    };

    // const getOrderSeverity = (order) => {
    //     switch (order.status) {
    //         case 'DELIVERED':
    //             return 'success';

    //         case 'CANCELLED':
    //             return 'danger';

    //         case 'PENDING':
    //             return 'warning';

    //         case 'RETURNED':
    //             return 'info';

    //         default:
    //             return null;
    //     }
    // };

    const allowExpansion = (rowData) => {
        return rowData?.debts?.length > 0;
    };

    const allow2Expansion = (rowData) => {
        return rowData.payments.length > 0;
    };

    const rowExpansionTemplate = (data) => {
        return (
            <Box
            bg={'orange.50'}
          
            rounded="lg"
            shadow="0px 0px 1px rgba(0,0,0,0.1)"
            p={6}
            m="10px auto"
            >
                <Heading fontSize={'md'} m={2}>Loans for {`${data.firstName} ${data.lastName}`}</Heading>
                {/* <DataTable value={data.debts}> */}
                <DataTable value={[...data.debts]} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansion2Template}
                    dataKey="id"  tableStyle={{ minWidth: '60rem' }}>
                    <Column expander={allow2Expansion} style={{ width: '5rem' }} />
                    <Column
                    header="#"
                    headerStyle={{ width: '3rem' }} 
                    body={(data, options) => options.rowIndex + 1}></Column>
                    <Column field='loanProduct.name' header='Loan Product Name' sortable ></Column>
                    <Column field="loanDate" header="Date" sortable body={loanBodyTemplate }></Column>
                    <Column field="loanDueDate" header="Loan Due Date" body={dateLoanBodyTemplate} sortable></Column>
                    <Column field='interest' header = 'Interest Amount($)' sortable></Column>
                    <Column field='loanProduct.currency.name' header = 'Currency' sortable></Column>

                    <Column field='balance' header = 'Balance($)' sortable></Column>

                    <Column field="stage" header="Loan Application Status" sortable body={statusOrderBodyTemplate} />

                    {/* <Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column> */}
                <Column field="stage" header="Status" sortable body={statusBodyTemplate} />
                {/* <Column headerStyle={{ width: '4rem' }} body={searchBodyTemplate}></Column> */}
                </DataTable>
            </Box>
        );
    };

    const rowExpansion2Template = (data) => {
        return (
            <Box
            bg={'blackAlpha.50'}
          
            rounded="lg"
            shadow="0px 0px 1px rgba(0,0,0,0.1)"
            p={6}
            m="10px auto"
            >
                <Heading fontSize={'md'} m={2}>Payment Schedule</Heading>
                {/* <h5>Payment Schedule</h5> */}
                <DataTable value={[...data.payments,...data.customPayments]}>
                    <Column field="paymentDate" header=" PaymentDate" sortable body={paymentBodyTemplate}></Column>
                    <Column field="paymentDueDate" header="Payment Due Date" body={dateBodyTemplate} sortable></Column>
                    <Column field='amount' header = 'Amount' sortable></Column>


                    {/* <Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column> */}
                <Column field="paymentStatus" header="Status" sortable body={statusBodyTemplate} />
                <Column headerStyle={{ width: '4rem' }} body={searchBodyTemplate}></Column>
                </DataTable>
            </Box>
        );
    };

    

    const header = (
        <ButtonGroup mb={2} gap={'4'}>
            <Button leftIcon={MdOutlineExpandMore} bg={'#001C30'} color={'white'} onClick={expandAll}>Expand All </Button>
            <Button leftIcon={MdOutlineExpandLess} bg={'orange.400'} color={'white'} onClick={collapseAll}>Collapse All</Button>
        </ButtonGroup>
    );

    return (
        <>
       <Breadcrumb ml={3} mb={2} fontWeight='medium' fontSize='sm'>
  <BreadcrumbItem>
    <BreadcrumbLink href='/admin/home.app'>Home</BreadcrumbLink>
  </BreadcrumbItem>

  <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='/admin/view-borrowers-loans'>Borrowers</BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>
        <Box
        bg={'white'}
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        p={6}
        m="10px auto"
        >
            <Heading fontSize={'xl'} m={2}>Borrowers</Heading>
            <Toast ref={toast} />
            <DataTable value={products} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}
                    dataKey="id" header={header} tableStyle={{ minWidth: '60rem' }}>
                <Column expander={allowExpansion} style={{ width: '5rem' }} />
                <Column field="firstName" header="Name" sortable />
                <Column field="lastName" header="LastName" sortable />
                <Column field="nationalIdNumber" header="National Id Number" sortable />
                <Column field="phoneNumber" header="Phone Number" sortable />



                {/* <Column header="Image" body={imageBodyTemplate} /> */}
                {/* <Column field="price" header="Price" sortable body={priceBodyTemplate} /> */}
                {/* <Column field="category" header="Category" sortable />
                <Column field="rating" header="Reviews" sortable body={ratingBodyTemplate} /> */}
                {/* <Column field="inventoryStatus" header="Status" sortable body={statusBodyTemplate} /> */}
            </DataTable>
        </Box>
        </>
    );
}
        