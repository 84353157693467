import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  useToast,
    StatHelpText,
    StatArrow,
    StatGroup,
    Stack,
    Heading

} from '@chakra-ui/react';
import { BsChevronRight, BsCurrencyDollar, BsPerson } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import {GiReceiveMoney,GiMoneyStack} from 'react-icons/gi'
import CustomerPendingList from '../form/CustomerPendingList';
import axios from '../../api/axios';
import React,{useEffect, useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { FaPeopleArrows, FaPeopleCarry, FaUserCheck, FaUserClock, FaUserMinus, FaUserPlus, FaUsers } from 'react-icons/fa';
import { useAuth } from '../../context/AuthProvider';

const GETSTATS_URL = '/onboarding/operator/dashboard';
const GETALLSTATS_URL = '/onboarding/operator/get-statistics'
const GETALLTELCO_URL ='/onboarding/telco/operator/get-statistics'
 
function StatsCard(props) {
   
    const { title, stat, icon } = props;
    return (
      <Stat
        px={{ base: 2, md: 4 }}
        py={'5'} 
        shadow={'xl'}
        border={'1px solid'}
        bgColor={useColorModeValue('white')}
        borderColor={useColorModeValue('white', 'white')}
        rounded={'lg'}>

        <Flex justifyContent={'space-between'}>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontWeight={'medium'} isTruncated>
              {title}
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'medium'} >
              {stat}
            </StatNumber>
          </Box>

          <Box
            my={'auto'}
            color={useColorModeValue('white', 'white')}
            alignContent={'center'}>
            {icon}
          </Box>

        </Flex>
      </Stat>
    );
  }
function IntAdmin() {
  const [statistics, setStatistics] = useState({});
  const [stats, setStats] = useState({})
  const [telco, setTelco] = useState({})
  const toast = useToast();
  const [loading, setLoading] = useState(false)


  const auth = useAuth();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);


  let user = localStorage.getItem('user')
    user = JSON.parse(user);

    const currentHour = new Date().getHours();
    let greeting;
  
    if (currentHour < 12) {
      greeting = 'Good morning';
    } else if (currentHour < 18) {
      greeting = 'Good afternoon';
    } else {
      greeting = 'Good evening';
    }
    
  return (
      <>
    <Box maxW="full"  pt={1} px={{ base: 2, sm: 12, md: 12 }}>
    <Breadcrumb spacing='8px' separator={<BsChevronRight color='gray.500' />}>
  <BreadcrumbItem isCurrentPage>
  <BreadcrumbLink href='#'></BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>
      <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        
        fontWeight={'bold'}>
     {greeting},
      </chakra.h1>
     
      
        <Stack
          spacing={4}
          
          minW={'10vw'}
          bg={useColorModeValue('gray.100', 'gray.800')}
          rounded={'xl'}
          boxShadow={'sm'}
          p={6}
          my={8}>

            
          
      <SimpleGrid mb={2} columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 4 }} >
          <StatsCard 
          
          title={'Loan Providers'}
          stat={1}
          icon={<FaUsers color='brown' size={'2em'} />}
          />
      
      <StatsCard
        title={'Loans'}
        stat={1}
        icon={<GiMoneyStack color='green' size={'2em'} />}
      />
    

  
      </SimpleGrid>
    </Stack>

  

    
    
    </Box>
          </>
  );
}

export default IntAdmin