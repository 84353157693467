import {
    Stack,
    HStack,
    Heading,
    Text,
    useColorModeValue,
    Box,
    Avatar,
    Flex,
    StackDivider,
    Center,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Select,
    Button,

} from '@chakra-ui/react';
import React, { useState } from 'react'
import { FaSearch } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom'

function ViewLoan() {
    const location = useLocation();
    const [details, setDetails] = useState(location.state)

    const navigate = useNavigate();

    return (
        <>
            <Flex mt={10} justifyContent={'flex-start'}>
                <Box

                    maxW={'20vw'}
                    w={'15vw'}
                    maxH={'80vh'}
                    h={'40vh'}
                    bg={useColorModeValue('whiteAlpha.500', 'gray.200')}
                    rounded={'sm'}
                    boxShadow={'xl'}
                    p={6}
                    my={4}>
                    <Heading mb={2} textAlign={'center'} lineHeight={1.1} textColor={'#64CCC5'} fontSize={{ base: '1xl', sm: '2xl' }}>
                        Personal Profile
                    </Heading>
                    <Stack mt={10} direction={['column', 'row']} spacing={6} align={'center'} justify={'center'}>

                        <Avatar
                            size={'2xl'}
                        // name={details.firstName}
                        />
                    </Stack>
                    <HStack>
                        <Box>
                            <Center>

                                <Text pt='2' ml={8} lineHeight={1.5} fontWeight={'semibold'} textColor={'#001C30'} fontSize={{ base: 'lg', sm: 'lg' }} >
                                    {details.firstName} {details.lastName}
                                </Text>
                            </Center>
                        </Box>
                    </HStack>
                </Box>

                <Box
                    ml={-2}
                    maxW={'80vw'}
                    maxH={'80vh'}
                    h={'40vh'}
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'md'}
                    boxShadow={'lg'}
                    p={6}
                    my={4}>
                    <Stack divider={<StackDivider />} spacing='6'>
                        <Heading mb={4} mt={4} lineHeight={0.1} textColor={'#64CCC5'} fontSize={{ base: 'lg', sm: 'lg' }} >
                            Information
                        </Heading>
                        <HStack spacing={12}>
                            <Box>
                                <Heading size='xs' textTransform='capitalize'>
                                    National ID:
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {details.nationalIdNumber}
                                </Text>
                            </Box>

                            <Box>
                                <Heading size='xs' textTransform='capitalize'>
                                    Phone Number:
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {details.phoneNumber}
                                </Text>
                            </Box>
                        </HStack>
                        <HStack spacing={12}>

                            <Box>
                                <Heading size='xs' textTransform='capitalize'>
                                    Home Address:
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                    {details.address}
                                </Text>
                            </Box>
                        </HStack>
                    </Stack>
                </Box>


                <Box bg={'white'} m={4} boxShadow="lg" borderRadius="sm" w={'850px'} maxW={'1300px'}>
                    <TableContainer>

                        <Table variant='striped' size={'lg'} colorScheme='gray'>
                            <TableCaption></TableCaption>

                            {/* <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Loan Date</Th>
        <Th>Loan DueDate</Th>
        <Th>Status</Th>
        <Th>Amount Borrowed</Th>
        <Th>Balance</Th>
      </Tr>
                                </Thead>
                                */}
                            <Tbody>
                                {details.debts.map((data, index) => {

                                    const loanDate = new Date(data.loanDate);
                                    const loandue = new Date(data.loanDueDate)

                                    const formattedLoanDate = loanDate.toLocaleDateString();
                                    const formattedDue = loandue.toLocaleDateString();
                                    const formattedDueTime = loandue.toLocaleTimeString();
                                    const formattedLoanTime = loanDate.toLocaleTimeString();
                                    return (
                                        <Accordion allowToggle>
                                            <AccordionItem>
                                            {data.stage === 'PROCESSED' ? (

                                            <>
                                                <AccordionButton>
                                                    <Tr>
                                                   
                                                        {/* <Td>{index + 1}</Td> */}
                                                        <Td><Heading size={'sm'}>Loan Date:</Heading> {formattedLoanDate}</Td>
                                                        <Td><Heading size={'sm'}>Loan DueDate:</Heading> {formattedDue}</Td>
                                                        <Td><Heading size={'sm'}>Status:</Heading> {data.paymentStatus}</Td>
                                                        <Td textAlign={'center'}><Heading size={'sm'}>Amount Borrowed:</Heading>${data.principal}</Td>
                                                        {data.balance < 0 ? (
                                                            <Td textColor={'red'}><Heading size={'sm'}>Balance: </Heading>$ {data.balance}</Td>
                                                            ) : (
                                                                
                                                                <Td textColor={'green'}><Heading size={'sm'}>Balance: </Heading>$ {data.balance}</Td>
                                                                )}
                                                              
                                                    </Tr>
                                                    <AccordionIcon ml={4} />
                                                </AccordionButton>
                                                <AccordionPanel>
                                                    <Table variant='simple' size={'sm'} colorScheme='gray'>
                                                        {/* <TableCaption>loan enquiry</TableCaption> */}
                                                        <Thead>
                                                            <Tr>
                                                                <Th>#</Th>
                                                                <Td>Transaction ID</Td>
                                                                <Th>Pay Date</Th>
                                                                <Th>Pay DueDate</Th>
                                                                <Th>Status</Th>
                                                                <Th>Amount Paid</Th>
                                                            </Tr>
                                                        </Thead>

                                                        <Tbody>
                                                            {[...data.payments,...data.customPayments].map((pay, index) => {

                                                                const payDate = new Date(pay.paymentDate);
                                                                const paydue = new Date(pay.paymentDueDate)

                                                                const formattedPayDate = payDate.toLocaleDateString();
                                                                const formattedPayDue = paydue.toLocaleDateString();
                                                                const formattedPayDueTime = paydue.toLocaleTimeString();
                                                                const formattedPayTime = payDate.toLocaleTimeString();
                                                                return (
<>


                                                                   {
                                                                   (pay.paymentStatus === 'PAID' && data.balance === 0)
                                                                   && <>
                                                                   <Tr>
                                                                    
                                                                   <Td>{index + 1}</Td>
                                                                   <Td>{pay.transactionId}</Td>
                                                                   <Td>{formattedPayDate}</Td>
                                                                   <Td>{formattedPayDue}</Td>
                                                                   {pay.paymentStatus === 'UNPAID' ? (
                                                                       <>
                                                                           <Td textColor={'red'}>$ {pay.paymentStatus}</Td>
                                                                           <Td textColor={'red'}>$ {pay.amount}</Td>
                                                                       </>
                                                                   ) : (
                                                                       <>
                                                                           <Td textColor={'green'}>$ {pay.paymentStatus}</Td>
                                                                           <Td textColor={'green'}>$ {pay.amount}</Td>
                                                                       </>

                                                                   )}



                                                               </Tr>
                                                                   </>
                                                                   }
                                                                   </>
                                                                )
                                                            })}
                                                            
                                                        </Tbody>
                                                    </Table>
                                                </AccordionPanel>
                                                </>

                                            ):(
                                                <></>
                                            )}
                                            </AccordionItem>
                                        </Accordion>
                                    )
                                })}

                            </Tbody>

                        </Table>

                    </TableContainer>
                </Box>
                </Flex>
                <Center>
                
                </Center>
        </>
    )
}

export default ViewLoan