import { Tag,Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ countdownDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [loading,setLoading] = useState(true)

  useEffect(() => {
   
    const timer = setTimeout(() => {
      const response = calculateTimeLeft()
      setTimeLeft(response.timeLeft);
      if(isNaN(response.difference)){
        setLoading(true)
      }else{
       setLoading(false)
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date(countdownDate) - +new Date();
    let timeLeft = {};

    

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return {timeLeft,difference};
  }

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }
    <Text color={'green'} m={2} fontWeight={'semibold'}>Link expires at:</Text>
    timerComponents.push(
      <Tag mx={0.5} variant='solid' colorScheme='teal' key={interval}>
        {timeLeft[interval]} {interval}{' '}
      </Tag>
    );
  });

  return (
    <div>
     {
     loading === false && <>
       {timerComponents.length > 0 ? (
        timerComponents
      ) : (
        <Tag variant='solid' colorScheme='red'>Link expired</Tag>
      )}
      </>
     }
    </div>
  );
};

export default CountdownTimer;