import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { 
  AlertDialog, 
  AlertDialogBody, 
  AlertDialogContent, 
  AlertDialogFooter, 
  AlertDialogHeader,
   Badge, Heading, Input, Stack, Text, Wrap, useDisclosure, useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  FormControl,
  FormLabel,
  Select,
  Tabs,
  Spinner,
  ButtonGroup, } from "@chakra-ui/react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Box, Button,chakra } from "@chakra-ui/react";
import { read, utils, writeFile } from 'xlsx'
import { BiExport } from 'react-icons/bi'
import { Tag } from 'primereact/tag';

import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdPreview } from "react-icons/md";
import { FiUserPlus } from "react-icons/fi";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from 'primereact/tristatecheckbox';


const GET_USERS_URL = 'mobiloan/auth/users'
const GETLOANPROVIDERS = '/mobiloan/loan-provider'
const ADD_USER_URL = '/mobiloan/auth/register'



export default function Users() {
  const [products, setProducts] = useState([]);
  const [getEmployee, setGetemployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open,setOpen] = useState(false)
  const [currentData,setCurrentData] = useState()
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  const [emailError, setEmailError] = useState('')
  const [fnameError, setFNameError] = useState('')
  const [provError, setProvError] = useState('')
  const [roleerror, setRoleError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

    let user = localStorage.getItem('user')
    user = JSON.parse(user);

    const [loanProvider, setLoanProvider] = useState([])
    const [selectProvider, setSelectProvider] = useState("")
    const[fullName, setFullName] = useState('')
    const [password, setPassword] = useState('')
    const [roles, setRoles] = useState([])
    const [selectedRole, setSelectedRole] = useState('');
    const [email, setEmail] = useState('')

    const getLoanProviders = async () => {
      setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${GETLOANPROVIDERS}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if(response.status === 200) {
        setLoanProvider(response.data.body)
        console.log(response.data.body);
        
        setLoading(false);
      }
    } catch (error) {
    
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast({
          title: "Auth Error",
          description: `Your session has expired, enter password to renew session.`,
          status: "error",
          variant:'left-accent',
          position: 'top',
          duration: 5000,
          isClosable: true,
        });
       
      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        });
     
      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error:`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
       
      }
    }
  }

  useEffect(() => {
     
        getLoanProviders();      
    }, [])


    const handleExport = () => {
      const headings = [["Fullname","Username","Status","Created At"]];
      const wb = utils.book_new();
      const ws = utils.table_to_sheet(document.getElementById('emp'),{raw: true});
      utils.sheet_add_aoa(ws, headings);
   //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
      utils.book_append_sheet(wb, ws, "Users_Report");
      writeFile(wb, "Users_Report.xlsx")
       };

       const handleAddUser = () => {
            setErrorMessage('')
        const res = validate();
            if (!res) {
          console.log('validation failed')
          toast({
            title: 'Failed',
            description: "Form not complete",
            status: 'error',
            duration: 3000,
      
          });
          return;
        }
            setLoading(true)
            let user = localStorage.getItem('user')
            user = JSON.parse(user);
            const token = localStorage.getItem("token");
            axios.post(`${ADD_USER_URL}`, {
              fullName,
              email,
              roles: [selectedRole],
              systemUserType: user.userType,
              password: "DefaultPass"
              
            },{
              headers: { 
                "Content-type": "application/json",
                'Authorization': `Bearer ${token}`
              }
              
            })
            .then(response => {
                // console.log(response)
                if (response.data.success) {
                    // getAllOrg();
                    getAllEmployees();
                    onClose();
                    toast({
                      title: 'User Creation Success',
                      description: response.data.message,
                      status: 'success',
                      position:'top',
                      variant: 'left-accent',
                      duration: 6000,
                      
                    });
                    setLoading(false)
                  }
                
                else {
                  setLoading(false)
                  toast({
                    title: 'User Creation Failed',
                    description: response.data.message,
                    status: 'error',
                    variant:'left-accent',
                    duration: 3000,
                   
                  });
                }
            })
            .catch(error => {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  toast({
                    title: "Acount Error",
                    description: `Server responded with ${error.response.data.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                  setLoading(false)
                } else if (error.request) {
                  // The request was made but no response was received
                  toast({
                    title: "Network Error",
                    description: "No response from server",
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                  });
                  setLoading(false)
                } else {
                  // Something happened in setting up the request that triggered an Error
                  toast({
                    title: "Network Error",
                    description: `Error: ${error.data.message}`,
                    status: "error",
                    variant:'top-accent',
                    duration: 8000,
                    isClosable: true,
                  });
                 
                }
              })
        }

        
       const handleExtAddUser = () => {
            setErrorMessage('')
        const res = validate();
            if (!res) {
          console.log('validation failed')
          toast({
            title: 'Failed',
            description: "Form not complete",
            status: 'error',
            duration: 3000,
      
          });
          return;
        }
            setLoading(true)
            let user = localStorage.getItem('user')
            user = JSON.parse(user);
            const token = localStorage.getItem("token");
            axios.post(`${ADD_USER_URL}`, {
              fullName,
              email,
              roles: [selectedRole],
              systemUserType: "EXTERNAL_USER",
              loanProvider: selectProvider,
              password: "DefaultPass"
              
            },{
              headers: { 
                "Content-type": "application/json",
                'Authorization': `Bearer ${token}`
              }
              
            })
            .then(response => {
                // console.log(response)
                if (response.data.success) {
                    // getAllOrg();
                    getAllEmployees();
                    onClose();
                    toast({
                      title: 'User Creation Success',
                      description: response.data.message,
                      status: 'success',
                      position:'top',
                      variant: 'left-accent',
                      duration: 6000,
                      
                    });
                    setLoading(false)
                  }
                
                else {
                  setLoading(false)
                  toast({
                    title: 'User Creation Failed',
                    description: response.data.message,
                    status: 'error',
                    variant:'left-accent',
                    duration: 3000,
                   
                  });
                }
            })
            .catch(error => {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  toast({
                    title: "Error",
                    description: `Server responded with ${error.response.data.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                  setLoading(false)
                } else if (error.request) {
                  // The request was made but no response was received
                  toast({
                    title: "Network Error",
                    description: "No response from server",
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                  });
                  setLoading(false)
                } else {
                  // Something happened in setting up the request that triggered an Error
                  toast({
                    title: "Network Error",
                    description: `Error: ${error.data.message}`,
                    status: "error",
                    variant:'top-accent',
                    duration: 8000,
                    isClosable: true,
                  });
                 
                }
              })
        }

        const validate = () =>{
          const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
          const PHONE_REGEX = /^(712980)/;
      
          if (!fullName) {
            setFNameError('Full name is required');
            return false;
          }
      
          if (!email) {
            setEmailError('email is required');
            return false;
          }
      
          if (!EMAIL_REGEX.test(email)) {
            setEmailError('email not valid');
            return false;
          }
        
          if (!selectedRole) {
            setRoleError('Please select roles');
            return false;
          }
          if (!loanProvider) {
            setProvError('Please select loan provider');
            return false;
          }
         
        
      
          return true
           
         }
  

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    fullName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    roles: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    dateCreated: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS}] },

  });

  /* Fetching Data From Server */
    const getAllEmployees = async () => {
        const token = localStorage.getItem("token");
        try {
          setLoading(true);
          const response = await axios.get(`${GET_USERS_URL}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (response.status === 200) {
              
                setGetemployee(response.data.body);
                // setGetInternalemployee(response.data.body)
                console.log(response.data.body);
        
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      };
      useEffect(() => {
        getAllEmployees();
      }, []);

      const filterUsers = getEmployee.filter(useer => user.userType === useer.systemUserType)
      console.log(filterUsers);
      
      // const provider = filterUsers.flatMap(prov => prov.loanProvider)
      // console.log(provider)
      const filterExtUser = filterUsers.filter(uk => uk.loanProvider?.id === user.loanProviderId)
  console.log(filterExtUser)

  // const handleDelete = async (data) => {
  //   setLoading(true)
  //   const token = localStorage.getItem("token");
  //   try {
      
  //     const response = await axios.delete(DELETEUSER_URL + `${data.id}`, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       }
  //     });
  //     if(response.data.success) {
  //       getUsers();
  //       onClose();
  //       toast({
  //         title: 'Success',
  //         description: "Deleted",
  //         status: 'success',
  //         duration: 6000,
          
  //       });
  //       setLoading(false)
      
  //     }else{
  //       toast({
  //         title: 'Failed',
  //         description: "Failed to Delete",
  //         status: 'error',
  //         duration: 6000,
          
  //       });
  //       setLoading(false)
  //     }
  //     setOpen(false)
  //   } catch (error) {
      
  //   }
  // };

  // const date = new Date(dateOfBirth);
  // const formattedDate = date.toLocaleDateString();

  const formatDate = (value) => {
    const date = new Date(value)
    return date.toLocaleDateString( 'en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
};

  /* End Of Fetching Data From Server */

  /* Table Header */
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <Input
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.dateCreated);
  
  };
  const statusOrderBodyTemplate = (rowData) => {
    return <Tag  severity={getOrderSeverity(rowData)}>

        
        { rowData.roles[0].name === "ROLE_ADMIN" ? 'Admin': rowData.roles[0].name === 'ROLE_USER' ? 'General User ' :""

        }
    </Tag>;
};

  const verifiedBodyTemplate = (rowData) => {
    // return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.employeeAccount.accountVerified, 'text-red-500 pi-times-circle': !rowData.employeeAccount.accountVerified })}></i>;
    return  rowData.verified === true ? (<Badge  textTransform={'capitalize'} bg={'green.400'} color={'white'} rounded={'md'} boxShadow={'sm'} >
    Active
  </Badge>): (
                    <Badge textTransform={'capitalize'} bg={'red.400'} color={'white'} rounded={'md'} boxShadow={'sm'} >
                    Deactivated
                  </Badge>
                  )

};

const getOrderSeverity = (getEmployee) => {
    switch (getEmployee.roles[0].name) {
        case 'ROLE_ADMIN':
            return 'warning';
  
        case 'ROLE_USER':
        return 'INFO';
  
        default:
            return null;
    }
  };
  

const verifiedFilterTemplate = (options) => {
//   console.log(options)
  return (
      <div className="flex align-items-center gap-2">
          <label htmlFor="verified-filter" className="font-bold">
              {/* Verified */}
          </label>
          <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
      </div>
  );
};

const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Wrap spacing={2} direction='row' align='center'>
            <Button colorScheme='gray' size='xs' leftIcon={<MdPreview />} onClick={() => navigate(`/admin/view-user`,{
                state: rowData
              })} >View</Button>
            {/* <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} /> */}
            {/* <Button onClick={()=>{
            setCurrentData(rowData)
            setOpen(true)}}   colorScheme='red' size='xs' leftIcon={<MdDelete />}>Delete</Button>  */}
               </Wrap>
        </React.Fragment>
    );
};




  const header = renderHeader();
  /* End of Table Header */

 

  return (
    <>


 
                {/* <Button
                leftIcon={<BiExport/>}
                isLoading={loading}
                loadingText='Loading'
                spinnerPlacement='end'
                onClick={handleExport}
                bg={'blue'}
                color={'white'}
                _hover={{
                    bg: 'orange.500',
                }}
                 mt={-2}
                ml={2}
                variant='solid'
                w={'150px'}>
                    Export to Excel
                </Button> */}
             <Button onClick={onOpen} ml={2} mb={4}  bg={'#001C30'} color={'white'} size='md' variant='solid'>
    Create User
  </Button>
  {user.userType === 'INTERNAL_USER' &&
  
    <Box
    mt={4}
    boxShadow={'lg'}
    rounded={'md'}
    >
      
      <DataTable
      id="emp"
        value={filterUsers}
        header={header}
        scrollable 
        scrollHeight="600px"
        paginator
        rows={100}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        filters={filters}
        showGridlines
        filterDisplay="row"
        loading={loading}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        globalFilterFields={[
          "username",
          "fullName",
          "roles",
        
      
       
        ]}
        emptyMessage="No employees found.">
              <Column
          header="#"
           headerStyle={{ width: '3rem' }} 
           body={(data, options) => options.rowIndex + 1}></Column>
        <Column
          field="fullName"
          header="Name"
          sortable
          filter
          filterField="fullname"
          filterPlaceholder="Search by name"
          style={{ minWidth: "12rem" }}></Column>
        <Column
          field="username"
          header="UserName"
          sortable
          filter
          filterField="username"
          filterPlaceholder="Search by last name"
          style={{ minWidth: "12rem" }}></Column>
          <Column
          field="roles.name"
          header="Role"
          filterPlaceholder="Search by role"
          body={statusOrderBodyTemplate}
          style={{ minWidth: "10rem" }}></Column>

        <Column field="verified" header="Status" dataType="boolean"  style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} filter filterElement={verifiedFilterTemplate} />
        <Column
          field="dateCreated"
          header="Created At"
          body={dateBodyTemplate}
          style={{ minWidth: "8rem" }}></Column>
        {/* <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem'  }}></Column> */}

          
         
      </DataTable>
      </Box>
  }
 {user.userType === 'EXTERNAL_USER' &&
 
    <Box
    mt={4}
    boxShadow={'lg'}
    rounded={'md'}
    >
      
      <DataTable
      id="emp"
        value={filterExtUser}
        header={header}
        scrollable 
        scrollHeight="600px"
        paginator
        rows={100}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        filters={filters}
        showGridlines
        filterDisplay="row"
        loading={loading}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        globalFilterFields={[
          "username",
          "fullName",
          "roles",
        
      
       
        ]}
        emptyMessage="No users found.">
              <Column
          header="#"
           headerStyle={{ width: '3rem' }} 
           body={(data, options) => options.rowIndex + 1}></Column>
        <Column
          field="fullName"
          header="Name"
          sortable
          filter
          filterField="fullname"
          filterPlaceholder="Search by name"
          style={{ minWidth: "12rem" }}></Column>
        <Column
          field="username"
          header="Email"
          sortable
          filter
          filterField="username"
          filterPlaceholder="Search by email"
          style={{ minWidth: "12rem" }}></Column>
          <Column
          field="roles.name"
          header="Role"
          filterPlaceholder="Search by role"
          body={statusOrderBodyTemplate}
          style={{ minWidth: "10rem" }}></Column>

        <Column field="verified" header="Status" dataType="boolean"  style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} filter filterElement={verifiedFilterTemplate} />
        <Column
          field="dateCreated"
          header="Created At"
          body={dateBodyTemplate}
          style={{ minWidth: "8rem" }}></Column>
        {/* <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem'  }}></Column> */}

          
         
      </DataTable>
      </Box>
 }
 

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create your account</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {user.userType === 'INTERNAL_USER'?
          (
            <Tabs size='md' variant='enclosed'>
            <TabList>
              <Tab>Internal Users</Tab>
              <Tab>External Users</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {/* Internal */}
                  <Box p={1}>
          
                  <HStack spacing={5}>
                  
                  <FormControl>
                    <FormLabel>Full name</FormLabel>
                    <Input ref={initialRef} 
                    placeholder='Full name' type="text" 
                    onChange={(e)=>setFullName(e.target.value)}
                    onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}
                  
                    />
                     <Text color={'red'}>{fnameError}</Text>
                  
                  </FormControl>
                  
                  <FormControl mt={4}>
                    <FormLabel>Email</FormLabel>
                    <Input placeholder='email' 
                    type="email" 
                    onChange={(e)=>setEmail(e.target.value)} 
                    onFocus={() => { setEmailError('') }} borderColor={emailError ? 'red.400' : 'gray.300'}
                    />
                     <Text color={'red'}>{emailError}</Text>
                  
                  </FormControl>
                  </HStack>
                  <HStack spacing={4}>
                  <FormControl>
                  <FormLabel>Select Roles</FormLabel>
                  <Select 
                  onChange={(e)=>setSelectedRole(e.target.value)} 
                  
                      placeholder='Select Role'
                      onFocus={() => { setRoleError('') }} borderColor={roleerror ? 'red.400' : 'gray.300'}
                      >
                    <option  value="ROLE_USER">User</option>
                    <option value="ROLE_ADMIN">Admin</option>
                  </Select>
                  <Text color={'red'}>{roleerror}</Text>
                  </FormControl>
                  
                  </HStack>
                  <ButtonGroup>
                  <Button 
                  onClick={handleAddUser}
                  colorScheme='teal' mr={3} mt={4} >
                                Register
                              </Button>
                  </ButtonGroup>
                  </Box>
              </TabPanel>
              <TabPanel>
                {/* External */}
              <Box p={1}>
          
              <HStack spacing={5}>
          
          <FormControl>
            <FormLabel>Full name</FormLabel>
            <Input ref={initialRef} 
            placeholder='Full name' type="text" 
            onChange={(e)=>setFullName(e.target.value)}
            onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}
          
            />
             <Text color={'red'}>{fnameError}</Text>
          
          </FormControl>
          
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input placeholder='email' 
            type="email" 
            onChange={(e)=>setEmail(e.target.value)} 
            onFocus={() => { setEmailError('') }} borderColor={emailError ? 'red.400' : 'gray.300'}
            />
             <Text color={'red'}>{emailError}</Text>
          
          </FormControl>
          </HStack>
          <HStack spacing={4} mt={4}>
          <FormControl>
          <FormLabel>Select Roles</FormLabel>
          <Select 
          onChange={(e)=>setSelectedRole(e.target.value)} 
          
              placeholder='Select Role'
              onFocus={() => { setRoleError('') }} borderColor={roleerror ? 'red.400' : 'gray.300'}
              >
            <option  value="ROLE_USER">User</option>
            <option value="ROLE_ADMIN">Admin</option>
          </Select>
          <Text color={'red'}>{roleerror}</Text>
          </FormControl>
          
           <FormControl>
              <FormLabel>Loan Provider</FormLabel>
              <Select 
                        _hover={{
                          borderRadius: 'orange',
                        }} 
                        placeholder='Select Provider'
                         name='selectProvider'
                        onChange={(e)=> setSelectProvider(e.target.value)}
                        onFocus={() => { setProvError('') }} borderColor={provError ? 'red.400' : 'gray.300'}
                        >
                {loanProvider?.map((userOrg) => (
                  <option key={userOrg.id} value={userOrg.id}>
                    {userOrg.name}
                  </option>
                ))}
              
                  
                </Select>
                <Text color={'red'}>{provError}</Text>
                </FormControl>
          
          
          </HStack>
          <ButtonGroup>
          <Button 
          onClick={handleExtAddUser}
          colorScheme='orange' mr={3} mt={4} >
                        Register
                      </Button>
          </ButtonGroup>
          </Box>
              </TabPanel>
            </TabPanels>
                    </Tabs>
          ) :
          (
            <Box p={1}>
          
            <HStack spacing={5}>
        
        <FormControl>
          <FormLabel>Full name</FormLabel>
          <Input ref={initialRef} 
          placeholder='Full name' type="text" 
          onChange={(e)=>setFullName(e.target.value)}
          onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}
        
          />
           <Text color={'red'}>{fnameError}</Text>
        
        </FormControl>
        
        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input placeholder='email' 
          type="email" 
          onChange={(e)=>setEmail(e.target.value)} 
          onFocus={() => { setEmailError('') }} borderColor={emailError ? 'red.400' : 'gray.300'}
          />
           <Text color={'red'}>{emailError}</Text>
        
        </FormControl>
        </HStack>
        <HStack spacing={4} mt={4}>
        <FormControl>
        <FormLabel>Select Roles</FormLabel>
        <Select 
        onChange={(e)=>setSelectedRole(e.target.value)} 
        
            placeholder='Select Role'
            onFocus={() => { setRoleError('') }} borderColor={roleerror ? 'red.400' : 'gray.300'}
            >
          <option  value="ROLE_USER">User</option>
          <option value="ROLE_ADMIN">Admin</option>
        </Select>
        <Text color={'red'}>{roleerror}</Text>
        </FormControl>
        
         <FormControl>
            <FormLabel>Loan Provider</FormLabel>
            <Select 
                      _hover={{
                        borderRadius: 'orange',
                      }} 
                      placeholder='Select Provider'
                       name='organizationId'
                      onChange={(e)=> setLoanProvider(e.target.value)}
                      onFocus={() => { setProvError('') }} borderColor={provError ? 'red.400' : 'gray.300'}
                      >
              {loanProvider?.length === 0 && <Spinner />}
              {loanProvider?.map((userOrg) => (
                <option key={userOrg.id} value={userOrg.id}>
                  {userOrg.name}
                </option>
              ))}
            
                
              </Select>
              <Text color={'red'}>{provError}</Text>
              </FormControl>
        
        
        </HStack>
        <ButtonGroup>
        <Button 
        onClick={handleExtAddUser}
        colorScheme='orange' mr={3} mt={4} >
                      Register
                    </Button>
        </ButtonGroup>
        </Box>
          ) 
          }
         
          
          </ModalBody>

          <ModalFooter>
           
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}