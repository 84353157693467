
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Container,
    Flex,
    Stack,
    Heading,
    Wrap,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    useDisclosure,
    FormControl,
    FormLabel,
    Select,
    Spinner,
    Text,
    InputGroup,
    InputElementProps,
    InputRightElement,
    HStack,
    VStack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Box,
  } from '@chakra-ui/react'
  import axios from '../../api/axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { read, utils, writeFile } from 'xlsx'
import { MdUpdate } from 'react-icons/md';


  const GETLOANPROVIDERS = '/mobiloan/loan-provider'
  const ADDLOANPROVIDER_URL = '/mobiloan/loan-provider'
  const UPDATELOANPROVIDER_URL = '/mobiloan/loan-provider/'


function AddLoanProvider() {
    const [loanprovider, setLoanProvider] = useState([])
    const [loading,setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const {isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose} = useDisclosure();
    const editRef = React.useRef(null);


    const [name, setName] = useState('');
    const [providerShortCode, setProviderShortCode] = useState('');
    const [receiverShortCode, setReceiverShortCode] = useState('');
    const [ providerUsername, setProviderUsername] = useState('')
    const [providerUserPassword, setProviderUserPassword ] = useState('');
    const [currentSelected,setCurrentSelected] = useState(null)

    const handlesubmit = () => {
    //     setErrorMessage('')
    // const res = validate();
    //     if (!res) {
    //   console.log('validation failed')
    //   toast({
    //     title: 'Failed',
    //     description: "Form not complete",
    //     status: 'error',
    //     duration: 3000,

    //   });
    //   return;
    // }
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.post(ADDLOANPROVIDER_URL, {
          name,
          providerShortCode,
          providerUsername,
          providerUserPassword,
          receiverShortCode,
          receiverUsername: providerShortCode,
          receiverPassword: providerUserPassword,

          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
            // console.log(response)
            if (response.data.success) {
                navigate('/admin/loan-provider')
                getLoanProviders();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }
   

    const getLoanProviders = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETLOANPROVIDERS}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setLoanProvider(response.data.body)
          console.log(response.data.body);
          setLoading(false);
        }
      } catch (error) {
      
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
         
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
       
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Error",
            description: `Error:`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });
         
        }
      }
    }

    useEffect(() => {
       
          getLoanProviders();      
      }, [])

// to edit loan provider details

// const inititateProvider = {
//   providername:currentSelected?.name,
//   providerCode: currentSelected?.providerShortCode,
//   providerUser: currentSelected?.providerUsername,
//   providerUserPass: currentSelected?.providerUserPassword
// }
// console.log(inititateProvider)
// const [myprovider, setMyProvider] = useState(inititateProvider)

// const {providername, providerCode, providerUser, providerUserPass} = myprovider
// const handleLoanProvider = (e) => {
//   const { name, value } = e.target;
//   setMyProvider({ ...myprovider, [name]: value });
// };

const handleEditProvider = () => {
  //     setErrorMessage('')
  // const res = validate();
  //     if (!res) {
  //   console.log('validation failed')
  //   toast({
  //     title: 'Failed',
  //     description: "Form not complete",
  //     status: 'error',
  //     duration: 3000,

  //   });
  //   return;
  // }
      setLoading(true)
      const token = localStorage.getItem("token");
      axios.put(UPDATELOANPROVIDER_URL + `${currentSelected?.id}`, {
        name,
        providerShortCode,
        providerUsername,
        providerUserPassword

        
      },{
        headers: { 
          "Content-type": "application/json",
          'Authorization': `Bearer ${token}`
        }
        
      })
      .then(response => {
          // console.log(response)
          if (response.data.success) {
              navigate('/admin/loan-provider')
              onEditClose();
              toast({
                title: 'Success',
                description: response.data.message,
                variant: 'top-accent',
                position: 'top',
                status: 'success',
                duration: 6000,
                
              });
              setLoading(false)
            }
          
          else {
            setLoading(false)
            toast({
              title: 'Failed Update',
              description: response.message,
              variant: "left-accent",
              position: 'top',
              status: 'error',
              duration: 3000,
             
            });
          }
      })
      .catch(error => {
          if (error.response.status === 401) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            navigate('/')
            toast({
              title: "Error",
              description: `Server responded with ${error.response.status}. Logout and login again`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "No response from server",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
           
          }
        })
  }


      const handleExport = () => {
        const headings = [[ "#","FullName", "NationalID","Phone Number","KYC Check Result", "Amount($)", "Reasons"]];
        const wb = utils.book_new();
        const ws = utils.table_to_sheet(document.getElementById('customers'));
        utils.sheet_add_aoa(ws, headings);
     //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
        utils.book_append_sheet(wb, ws, "ValidatedData_Report");
        writeFile(wb, "ValidatedData_Report.xlsx")
         };
  
         const validate = () =>{
           
         }
         const [filters, setFilters] = useState({
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
          name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          providerUsername: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          // phoneNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        //   email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        //   "role.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        
        });

         /* Table Header */
         const [globalFilterValue, setGlobalFilterValue] = useState("");
         const onGlobalFilterChange = (e) => {
           const value = e.target.value;
           let _filters = { ...filters };
       
           _filters["global"].value = value;
       
           setFilters(_filters);
           setGlobalFilterValue(value);
         };
         const renderHeader = () => {
           
           return (
             <div className="flex justify-content-end">
               <span className="p-input-icon-left">
                 <Input
                   value={globalFilterValue}
                   onChange={onGlobalFilterChange}
                   placeholder="Keyword Search"
                 />
               </span>
             </div>
           );
         };
       
       //   const dateBodyTemplate = (rowData) => {
       //     return formatDate(rowData.dateOfBirth);
         
       //   };
         
       
       //   const dateFilterTemplate = (options) => {
       //     return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
       //   };
   
       const actionBodyTemplate = (rowData) => {
         return (
             <React.Fragment>
                 <Wrap spacing={2} direction='row' align='center'>
                 <Button
                 onClick={()=>{
                  onEditOpen()
                  setCurrentSelected(rowData)
                  setName(rowData.name)
                  setProviderUsername(rowData.providerUsername)
                  setProviderShortCode(rowData.providerShortCode)
                  setProviderUserPassword(rowData.providerUserPassword)
                }}
            rightIcon={<MdUpdate/>}
            bg={'yellow.500'}
            color={'white'}
            size={'md'}
            _hover={{
              bg: 'blue.500',
            }}>
            Update 
          </Button>
                
                
                    </Wrap>
             </React.Fragment>
         );
     };
       
         const header = renderHeader();
         /* End of Table Header */

    

  return (
    <>
    {/* <Heading fontSize={'xl'} m={8}>List of Loan Providers</Heading> */}
   
    {/* <Stack bg={'white'} m={10} boxShadow="lg"  borderRadius="sm">
        <TableContainer  >
    <Table size='md'>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Loan Provider</Th>
          <Th>Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {loanprovider.map((data, index) => {
            return(
            <Tr key={index}>
            <Td>{index + 1}</Td>
            <Td>{data.name}</Td>
            <Td>
            <Wrap spacing={2} direction='row' align='center'>
            <Button onClick={() => navigate(`/admin/view-provider`,{
              state:data
            })}  bg={'#001C30'} color={'white'} size='xs'>View</Button>
                <Button onClick={() => navigate(`/admin/view-loan-provider`,{
                    state:data
                })} colorScheme='yellow' size='xs'>Edit</Button>
                
              
                </Wrap>

            </Td>
            </Tr>


            )
        })}
      </Tbody>
    </Table>
  </TableContainer>
  </Stack> */}

<Container maxW={'60xl'}>

<Breadcrumb ml={3} mb={2} fontWeight='medium' fontSize='sm'>
  <BreadcrumbItem>
    <BreadcrumbLink href='/admin/super-home'>Home</BreadcrumbLink>
  </BreadcrumbItem>
  <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='/admin/loan-provider'>Loan Provider</BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>
        {/* <Button
                leftIcon={<BiUserPlus/>}
                onClick={onOpen} 
                bg={'#EDEBCA'}
                color={'black'}
                _hover={{
                    bg: '#2C2727',
                    color: 'white',
                }}
                mt={2}
                ml={2}
                variant='solid'
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                rounded={'md'}
                w={'150px'}>
                    Create User
                </Button> */}

    <Box
    bg={'white'}
    borderWidth="1px"
    rounded="lg"
    shadow="1px 1px 3px rgba(0,0,0,0.3)"
    p={6}
    m="10px auto">

<Stack direction='row' spacing={4}>
    <Button onClick={onOpen} ml={2} mb={4}  bg={'#001C30'} color={'white'} size='md' variant='solid'>
    Create Loan Provider
  </Button>
  {/* Create loan provider */}
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Loan Provider</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <HStack spacing={4}>   
            <FormControl isRequired>
              <FormLabel>Loan Provider Name:</FormLabel>
              <Input type='text'  placeholder='name' onChange={(e) => setName(e.target.value)}  
            //   onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} 
              />
              {/* <Text color={'red'}>{nameError}</Text> */}
            </FormControl>
            </HStack>
          <HStack spacing={4}>
          <FormControl isRequired>
              <FormLabel htmlFor='phoneNumber'>Provider Username:</FormLabel>
             
                <Input id='phoneNumber'
                  type='text'
                  placeholder='username'
                  _placeholder={{ color: 'gray.500' }}
                  name='providerUsername'
                    onChange={(e) => setProviderUsername(e.target.value)}

                />
            
              {/* <Text color={'red'}>{phoneError}</Text> */}
            </FormControl>
          
          </HStack>
          <VStack mt={2} spacing={2}>
          <FormControl isRequired>
            <FormLabel>Provider Shortcode:</FormLabel>
            <Input type={'text'} placeholder='shortcode' onChange={(e) => setProviderShortCode(e.target.value)}  
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Receiver Shortcode:</FormLabel>
            <Input type={'text'} placeholder='shortcode' onChange={(e) => setReceiverShortCode(e.target.value)}  
            />
          </FormControl>
          

           <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  placeholder="Enter password"
                 onChange={(e)=> setProviderUserPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {/* <Text color={'red'}>{passwordError}</Text> */}
            </FormControl>
            </VStack>
            {/* {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            } */}
          </ModalBody>

          <ModalFooter>
            <Button 
           onClick={handlesubmit} 
           isLoading={loading}
           loadingText='Loading'
           spinnerPlacement='start'
           colorScheme='orange' mr={3}>
             Save
           </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
      initialFocusRef={editRef}
      isOpen={isEditOpen}
      onClose={onEditClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Loan Provider</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <HStack spacing={4}>   
          <FormControl isRequired>
            <FormLabel>Loan Provider Name:</FormLabel>
            <Input type='text'  placeholder='name' name='name' value={name} onChange={(e)=> setName(e.target.value)}   
          //   onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} 
            />
            {/* <Text color={'red'}>{nameError}</Text> */}
          </FormControl>
          </HStack>
        <HStack spacing={4}>
        <FormControl isRequired>
            <FormLabel htmlFor='phoneNumber'>Provider Username:</FormLabel>
           
              <Input id='phoneNumber'
                type='text'
                placeholder='username'
                _placeholder={{ color: 'gray.500' }}
                name='providerUsername'
                value={providerUsername}
                onChange={(e)=>setProviderUsername(e.target.value)}

              />
          
            {/* <Text color={'red'}>{phoneError}</Text> */}
          </FormControl>
       
        
        </HStack>
        <VStack mt={2} spacing={2}>
        <FormControl isRequired>
          <FormLabel>Provider Shortcode:</FormLabel>
          <Input type={'text'} placeholder='shortcode' name='providerShortCode' value={providerShortCode} onChange={(e)=>setProviderShortCode(e.target.value)}  
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Receiver Shortcode:</FormLabel>
          <Input type={'text'} placeholder='shortcode' name='receiverShortCode' value={receiverShortCode} onChange={(e)=>setReceiverShortCode(e.target.value)}  
          />
        </FormControl>

         <FormControl isRequired>
            <FormLabel htmlFor="password">Password</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? 'text' : 'password'}
                placeholder="Enter password"
                value={providerUserPassword}
                name='providerUserPassword'
               onChange={(e)=>setProviderUserPassword(e.target.value)}

              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
            {/* <Text color={'red'}>{passwordError}</Text> */}
          </FormControl>
          </VStack>
          {/* {
            errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
          } */}
        </ModalBody>

        <ModalFooter>
          <Button 
         onClick={handleEditProvider} 
         isLoading={loading}
         loadingText='Loading'
         spinnerPlacement='start'
         bg={'#001C30'}
         color={'white'}
          mr={3}>
           Update
         </Button>
          <Button colorScheme='red' onClick={onEditClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal> 
  </Stack>
     
    <>
    <DataTable
      id="id"
        value={loanprovider}
        header={header}
        paginator
        rows={10}
        dataKey="id"
        tableStyle={{ minWidth: "40rem" }}
        filters={filters}
        showGridlines
        filterDisplay="row"
        loading={loading}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        globalFilterFields={[
          "name",
      
        ]}
        emptyMessage="No employees found.">
        <Column
          field="name"
          header="Loan Provider"
          sortable
          filter
          filterPlaceholder="Search by loan provider name"
          style={{ minWidth: "12rem" }}></Column>

<Column
          field="providerUsername"
          header="Username"
          style={{ minWidth: "8rem" }}></Column>

<Column
          field="providerShortCode"
          header="Disbursements shortcode"
          style={{ minWidth: "10rem" }}></Column>

<Column
          field="receiverShortCode"
          header="Repayment shortCode"
          style={{ minWidth: "10rem" }}></Column>

 <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem'  }}></Column>

    
      </DataTable>
</>
 
 
      </Box>
        </Container>
  </>
  )
}

export default AddLoanProvider