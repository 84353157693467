import React, { useState, useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Text,
  Stack,
  AlertIcon,
  Alert,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthProvider';

const TokenExpirationHandler = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const EmailRef = useRef();
  const navigate = useNavigate();
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (token) {
        const tokenData = JSON.parse(atob(token.split('.')[1])); // Assuming JWT token
        const expiry = tokenData.exp * 1000; // Convert to milliseconds
        const currentTime = Date.now();

        if (expiry < currentTime) {
          setIsTokenExpired(true);
          onOpen(); // Open the alert dialog
        }
      }
    };

    // Check token expiration on component mount
    checkTokenExpiration();

    // Optionally, set an interval to check token periodically (e.g., every minute)
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every minute

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [onOpen]);

  const handleLogout = () => {
    // Clear the token and redirect to login
    localStorage.clear();
    navigate('/');
    onClose();
  };

  let user = localStorage.getItem('user')
  user = JSON.parse(user);

  const auth = useAuth();

  const [email, setWorkEmail] = useState(user?.email);
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loggedin, setLoggedIn] = useState(false)
  const toast = useToast();
  const [ emailError, setEmailError] = useState('');
  const [ passwordError, setPasswordError] = useState('');

  //  token =  eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJub2FocnVmYmFuZGEzNEBnbWFpbC5jb20iLCJpYXQiOjE2NzY4NDIwOTMsImV4cCI6MTY3NjkyODQ5M30.64LdBGuhh4ulvbi2_AlzETiNOLstSQB4eS6CQTo3hklMlcpN591tE3mgt2Q30Uq7MT0uy3Epg5kXk4GLS1ZD_Q

  const login = async () => {
    // setErrMsg('')
    // const res = validation();
    // if (!res)  { 
    //   console.log('validation failed')
    //   toast({
    //     title: 'Failed',
    //     description: "Please enter details",
    //     status: 'error',
    //     duration: 6000,
    //   });
    //   return;
      
    // } 
  try {
    setLoading(true);
    setSuccess(true);
    const logInSucc = await auth.signin(email,password);
    if(logInSucc) {
      setSuccess(logInSucc);
      let user = localStorage.getItem('user')
      user = JSON.parse(user);
      console.log(user)
      onClose();
      if (user.userType === 'INTERNAL_USER') {
        if (user.roles[0] === 'ROLE_ADMIN') {
          
          navigate('/admin/admin.dashboard')
        }else{
          navigate('/admin/user.dashboard');
        }
      
      }
      else if (user.userType === 'EXTERNAL_USER'  ){
        if (user.roles[0] === 'ROLE_ADMIN') {
          navigate('/user/exadmin.dashboard')
        }else{

          navigate('/user/exuser.dashboard')
        }
      }
    
      else{
        navigate('/error404')
      }
      
      toast({
        title: 'Success',
        description: 'Logged in successfully',
        variant: "top-accent",
        position: "top",
        status: 'success',
        duration: 6000,
        
      });
      
      setLoading(false)
    }
    
    else {
      setLoading(false);
      setSuccess(false);
      toast({
        title: "Failed To Log In",
        description: "User Log In Failed: Incorrect Username / Password",
        variant: "left-accent",
        position: 'top',
        status: "error",  
      });
    }
    
  } catch (error) {
       setSuccess(true);
       if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast({
          title: "Error",
          description: `Server responded with ${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false)
      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "No response from server",
          status: "error",
          duration: 8000,
          isClosable: true,
        });
        setLoading(false)
      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error: ${error.message}`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
       setLoading(false)
      }

  }
  
    
  }
  useEffect(() => {

  }, [loading])
  
  const validation = ()  => {

  const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

//   if (!email) {
//     setEmailError('Email is Required');
//     return false;  
//   } 

//    if (!EMAIL_REGEX.test(email)) {
//     setEmailError('Email is not valid');
//     return false;
    
//    }

   if (!password){
    setPasswordError('Password is required');
    return false;
   }

   return true

  }


  return (
    <>
      {isTokenExpired && (
        <AlertDialog
          isOpen={isOpen}
          closeOnOverlayClick={false}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay
           bg={'blackAlpha.300'}
           backdropFilter={'blur(10px) hue-rotate(90deg)'}
          >
            <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            <Alert status='warning'>
           <AlertIcon />
            Session Expired
            </Alert>
              </AlertDialogHeader>

              <AlertDialogBody>
                Your session has expired. Please log in again.
                <Stack spacing={2}>
            
                <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" onChange={(e) => setPassword(e.target.value)} 
              onFocus = {() => {setPasswordError('')}} borderColor = {passwordError ? 'red.400' : 'gray.300'}
              />
              <Text color={'red'}>{passwordError}</Text>
            </FormControl>
                </Stack>
            <Stack mt={2}>
              
              <Button 
               isLoading={loading} 
               loadingText='Loading'
               colorScheme='teal'
               variant='outline'
               spinnerPlacement='end'
                w={'auto'}
              onClick={login}
                bg={'#176B87'}
                color={'white'}
                _hover={{
                  bg: '#DAFFFB',
                  color:'black'
                }}>
                Renew
              </Button>
            </Stack>
              </AlertDialogBody>

              <AlertDialogFooter>
                {/* <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button> */}
                <Button colorScheme="red" onClick={handleLogout} ml={3}>
                  Logout
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default TokenExpirationHandler;
