import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  useToast,
  Text,
  Image,
  
} from '@chakra-ui/react';
import React, {useRef, useState,useEffect} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import c2 from '../../images/c2.png' 
import netone from '../../images/netone.png'
import netlogo from '../../images/netlogo.png'
import netmoney from '../../images/netmoney.jpg'
import { useAuth } from '../../context/AuthProvider';
import bg5 from '../../images/bg.jpeg';


export default function SignInDetail() {
  const auth = useAuth();
  // const userRef = useRef();
  // const errRef = useRef();
  const [email, setWorkEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loggedin, setLoggedIn] = useState(false)
  const navigate = useNavigate();
  const toast = useToast();
  const [ emailError, setEmailError] = useState('');
  const [ passwordError, setPasswordError] = useState('');

  //  token =  eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJub2FocnVmYmFuZGEzNEBnbWFpbC5jb20iLCJpYXQiOjE2NzY4NDIwOTMsImV4cCI6MTY3NjkyODQ5M30.64LdBGuhh4ulvbi2_AlzETiNOLstSQB4eS6CQTo3hklMlcpN591tE3mgt2Q30Uq7MT0uy3Epg5kXk4GLS1ZD_Q

  const login = async () => {
    setErrMsg('')
    const res = validation();
    if (!res)  { 
      console.log('validation failed')
      toast({
        title: 'Failed',
        description: "Please enter details",
        status: 'error',
        duration: 6000,
      });
      return;
      
    } 
  try {
    setLoading(true);
    setSuccess(true);
    const logInSucc = await auth.signin(email,password);
    if(logInSucc) {
      setSuccess(logInSucc);
      let user = localStorage.getItem('user')
      user = JSON.parse(user);
      console.log(user)
      if (user.userType === 'INTERNAL_USER') {
        if (user.roles[0] === 'ROLE_ADMIN') {
          
          navigate('/admin/admin.dashboard')
        }else{
          navigate('/admin/user.dashboard');
        }
      // }else if (user.userType === 'INTERNAL_USER' || user.roles[0] === 'ROLE_ADMIN'){
      //   navigate('/admin/admin.dashboard')
      }
      else if (user.userType === 'EXTERNAL_USER'  ){
        if (user.roles[0] === 'ROLE_ADMIN') {
          navigate('/user/exadmin.dashboard')
        }else{

          navigate('/user/exuser.dashboard')
        }
      }
      // else if (user.userType === 'EXTERNAL_USER' || user.roles[0] === 'ROLE_ADMIN'){
      //   navigate('/user/exadmin.dashboard')
      // }
      else{
        navigate('/error404')
      }
      
      toast({
        title: 'Success',
        description: 'Logged in successfully',
        variant: "top-accent",
        position: "top",
        status: 'success',
        duration: 6000,
        
      });
      
      setLoading(false)
    }
    
    else {
      setLoading(false);
      setSuccess(false);
      toast({
        title: "Failed To Log In",
        description: "User Log In Failed: Incorrect Username / Password",
        variant: "left-accent",
        position: 'top',
        status: "error",  
      });
    }
    
  } catch (error) {
       setSuccess(true);
       if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast({
          title: "Error",
          description: `Server responded with ${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false)
      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "No response from server",
          status: "error",
          duration: 8000,
          isClosable: true,
        });
        setLoading(false)
      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error: ${error.message}`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
       setLoading(false)
      }

  }
  
    
  }
  useEffect(() => {

  }, [loading])
  
  const validation = ()  => {

  const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (!email) {
    setEmailError('Email is Required');
    return false;  
  } 

   if (!EMAIL_REGEX.test(email)) {
    setEmailError('Email is not valid');
    return false;
    
   }

   if (!password){
    setPasswordError('Password is required');
    return false;
   }

   return true

  }

  // useEffect(() => {
  //   const checkIfUserLogged = async () => {
  //     if(!(await auth.isAuthenticated())) {
  //       setLoggedIn(false)
  //     } else {
  //       setLoggedIn(true)
  //     }
  //   }
  //   checkIfUserLogged()
  // }, []);

  return (

    <Flex
      minW={'full'}
      h="100vh"
      w="100%"
      bgSize={'cover'}
      bgPosition={'right'}
      // bg={useColorModeValue('gray.100')}
      bgImage={bg5}
      >
    
   
      <Stack spacing={6}  mx={'auto'} maxW={'md'} py={12} px={6}>
   

      <Stack align={'center'} >
          <Image 
            borderRadius='full'
            boxSize='90px'
            src={netmoney}
            
            alt='logo'
          
          
          />
          <Heading fontSize={'25px'}>MobiLoan</Heading>
          <Text>Sign in to your Account</Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e) => setWorkEmail(e.target.value)} 
              onFocus = {() => {setEmailError('')}} borderColor= {emailError ? 'red.400' : 'gray.300'}
              />
             <Text color={'red'}>{emailError} </Text>
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" onChange={(e) => setPassword(e.target.value)} 
              onFocus = {() => {setPasswordError('')}} borderColor = {passwordError ? 'red.400' : 'gray.300'}
              />
              <Text color={'red'}>{passwordError}</Text>
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Checkbox>Remember me</Checkbox>
                <Link color={'#176B87'}><NavLink to='/forgot-password' color='orange'>Forgot password?</NavLink></Link>
              </Stack>
              
              <Button 
               isLoading={loading} 
               loadingText='Loading'
               colorScheme='teal'
               variant='outline'
               spinnerPlacement='end'

              onClick={login}
                bg={'#176B87'}
                color={'white'}
                _hover={{
                  bg: '#DAFFFB',
                  color:'black'
                }}>
                Sign In
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
     
    </Flex>
  );
}