
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Container,
    Flex,
    Stack,
    Heading,
    Wrap,
    Button,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    useDisclosure,
    FormControl,
    FormLabel,
    Select,
    Spinner,
    Text,
    InputGroup,
    InputElementProps,
    InputRightElement,
    HStack,
    VStack,
  } from '@chakra-ui/react'
  import axios from '../../api/axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

  const GETLOANPROVIDERS = '/mobiloan/loan-provider'
  const GETCURRENCY_URL = '/mobiloan/currency'
  const ADDLOANPROVIDER_URL = '/mobiloan/loan-provider'

function AddLoanProduct() {
    const [loanprovider, setLoanProvider] = useState([])
    const [loading,setLoading] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);


    const [name, setName] = useState('');
    const [productName, setProductName] = useState('');
    const [minLoanAmount, setMinLoanAmount] = useState('');
    const [maxLoanAmount, setMaxLoanAmount] = useState('');
    const [providerShortCode, setProviderShortCode] = useState('');
    const [selectCurrency, setSelectCurrency] = useState([])
    const [currencyId, setCurrencyId] = useState('')
    const [ providerUsername, setProviderUsername] = useState('')
    const [providerUserPassword, setProviderUserPassword ] = useState('');

    const handlesubmit = () => {
    //     setErrorMessage('')
    // const res = validate();
    //     if (!res) {
    //   console.log('validation failed')
    //   toast({
    //     title: 'Failed',
    //     description: "Form not complete",
    //     status: 'error',
    //     duration: 3000,

    //   });
    //   return;
    // }
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.post(ADDLOANPROVIDER_URL, {
          name,
          productName,
          minLoanAmount,
          maxLoanAmount,
          providerShortCode,
          currency:currencyId,
          providerUsername,
          providerUserPassword

          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
            // console.log(response)
            if (response.data.success) {
                navigate('/admin/view-provider')
                getLoanProviders();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }
    let user = localStorage.getItem('user')
    user = JSON.parse(user);
    console.log(user)

    const getLoanProviders = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETLOANPROVIDERS}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setLoanProvider(response.data.body)
          console.log(response.data.body);
          setLoading(false);
        }
      } catch (error) {
      
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
         
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
       
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Error",
            description: `Error:`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });
         
        }
      }
    }

    useEffect(() => {
       
          getLoanProviders();      
      }, [])

      const getCurrencies = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(`${GETCURRENCY_URL}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
    
          if(response.status === 200) {
            setSelectCurrency(response.data.body)
            setLoading(false);
          }
        } catch (error) {
   
        }
      }
  
      useEffect(() => {
         
          getCurrencies();
        
        }, [])

  return (
    <>
    <Heading fontSize={'xl'} m={8}>List of Loan Providers</Heading>
    <Stack direction='row' spacing={4}>
    <Button onClick={onOpen} ml={8}  bg={'#001C30'} color={'white'} size='md' variant='solid'>
    Create Loan Provider
  </Button>
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Loan Provider</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <HStack spacing={4}>

           
            <FormControl isReadOnly>
              <FormLabel>Loan Provider Name:</FormLabel>
              <Input type='text'  value={loanprovider.name}
            //   onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} 
              />
              {/* <Text color={'red'}>{nameError}</Text> */}
            </FormControl>

            {/* <FormControl>
              <FormLabel>Loan Product:</FormLabel>
              <Input type={'text'} placeholder='name' onChange={(e) => setProductName(e.target.value)}  
              />
            </FormControl> */}
            </HStack>
            {/* <HStack spacing={4}>

          <FormControl>
            <FormLabel>Minimum Loan Amount</FormLabel>
            <Input type={'number'} placeholder='min amount' onChange={(e) => setMinLoanAmount(e.target.value)}  
            />
          </FormControl>
          <FormControl >
            <FormLabel>Maximum Loan Amount:</FormLabel>
            <Input type={'number'} placeholder='min amount' onChange={(e) => setMaxLoanAmount(e.target.value)}  
            />
          </FormControl>
          </HStack> */}
          
          
          {/* <FormControl>
          <FormLabel>Currency</FormLabel>
               <Select 
                      _hover={{
                        borderRadius: 'orange',
                      }} 
                      placeholder='Select Currency' name='currencyId' onChange={(e)=> setCurrencyId(e.target.value)}>
                 {selectCurrency.length === 0 && <Spinner />}
              {selectCurrency.map((currency) => (
                <option key={currency.id} value={currency.id}>
                  {currency.name}
                </option>
              ))} 
              </Select>
          </FormControl> */}
          
          <VStack mt={2} spacing={2}>

         
          <FormControl isRequired>
              <FormLabel htmlFor='phoneNumber'>Provider Username:</FormLabel>
             
                <Input id='phoneNumber'
                  type='text'
                  placeholder='username'
                  _placeholder={{ color: 'gray.500' }}
                  name='providerUsername'
                    onChange={(e) => setProviderUsername(e.target.value)}

                />
            
              {/* <Text color={'red'}>{phoneError}</Text> */}
            </FormControl>

           <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  placeholder="Enter password"
                 onChange={(e)=> setProviderUserPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {/* <Text color={'red'}>{passwordError}</Text> */}
            </FormControl>
            </VStack>
            {/* {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            } */}
          </ModalBody>

          <ModalFooter>
            <Button 
           onClick={handlesubmit} 
           isLoading={loading}
           loadingText='Loading'
           spinnerPlacement='start'
           colorScheme='orange' mr={3}>
             Save
           </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Stack>
    <Stack bg={'white'} m={10} boxShadow="lg"  borderRadius="sm">
        <TableContainer  >
    <Table size='md'>
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Name</Th>
          <Th>Product Name</Th>
          <Th>Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {loanprovider?.loanProducts?.map((data, index) => {
          console.log(loanprovider)
            return(

            <Tr key={index}>
            <Td>{index + 1}</Td>
            <Td>{data.name}</Td>
            <Td>
            <Wrap spacing={2} direction='row' align='center'>
                <Button  bg={'#001C30'} color={'white'} size='xs'>View</Button>
                {/* <Button onClick={() => navigate(`/admin/edit-cust`,{
                    state:data
                })} colorScheme='yellow' size='xs'>Edit</Button> */}
                
                {/* <Button colorScheme='red' size='xs'>Delete</Button> */}
                </Wrap>

            </Td>
            </Tr>


            )
        })}
      </Tbody>
    </Table>
  </TableContainer>
  </Stack>
  </>
  )
}

export default AddLoanProduct