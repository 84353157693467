import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react'
import { AuthProvider } from './context/AuthProvider';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/tailwind-light/theme.css";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <AuthProvider>
      <PrimeReactProvider>
    <ChakraProvider>
    <App />
    </ChakraProvider>
      </PrimeReactProvider>
     </AuthProvider>
  </React.StrictMode>
);

