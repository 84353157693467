import {createContext, useContext, useState, useEffect} from 'react'
import jwt from 'jwt-decode';
import {login} from "../services/login";

const AuthContext = createContext({});

export function AuthProvider  ({children})  {
    // const [auth, setAuth] = useState({});
    const auth = useProvideAuth();

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}
// export default AuthContext;
export const useAuth = () => {
    return useContext(AuthContext);
}

function useProvideAuth(){
    const [user, setUser] = useState({});
    const signin = async (email,password) =>{
        // const token = await login(workEmail,password);
        const res = await login(email,password)
        if(res.success){
          const { token, roles} = res.data;
        if(token !== 'invalid username and password'){
            // console.log(token)
            const decodedToken = jwt(token);
            const {sub: email, loanProviderId, userType, id} = decodedToken
            const user = {
                email: email,
                token: token,
                loanProviderId,
                id,
                userType,
                roles
                
                
              

            }
            setUser(user);
            delete user.token
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            // console.log(token)
            return true;
        }else{
            return false;
        }
    }
  }
    const signout = () => {

    }
    const isAuthenticated = async () => {
        if(localStorage.getItem("token")) {
          const { exp } = jwt(localStorage.getItem("token"));
          if(Date.now() <= exp * 1000) {
            return true
          } else {
            return false
          }
        }
      }
      useEffect(() => {
   
      }, [])
      return {
        user,
        signin,
        signout,
        isAuthenticated
      }
    
}


