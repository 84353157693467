import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { BsPerson } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';



function StatsCard(props) {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'} 
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}>
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

export default function Statistics() {
  return (
    <Box maxW="7xl" mx={'auto'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        py={5}
        fontWeight={'bold'}>
     Hi! Noah Banda
      </chakra.h1>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 6 }}>
      <Link href='/pending-customers' color={'orange.400'}>
     
          <StatsCard 
          
          title={'pending-customers'}
          stat={'100'}
          icon={<FiUsers size={'3em'} />}
        />
      </Link>
        <StatsCard
          title={'Active Customers'}
          stat={'1,000'}
          icon={<BsPerson size={'3em'} />}
        />
        <StatsCard
          title={'Registered Customers'}
          stat={'5,000'}
          icon={<FiUsers size={'3em'} />}
        />

       
      </SimpleGrid>
    </Box>
  );
}