import axios from "../api/axios";
// const LOGIN_URL = 'auth/employee/signin';
const LOGIN_URL = 'mobiloan/auth/signin';

export async function login(email,password){
  try {
      const response = await axios.post(LOGIN_URL, {email,password});
      const {
        token,
       roles
    } = response.data.body
     
      if(response.status === 200) {
        console.log(response)
          // return response.data.body
          if (response.data.success === true) {
            console.log(email)
            return {
                         success:true,
                          message: 'Login success',
                         data: {
                            roles,
                             token,
                            
                             
                          }
                        }
          }else{
            return {
              success:false,
               message: response.data.message,
             
             }
          }
        }
        else {
          return {success: false, message: response.data.message, data: null}
        }
        // else if(response.success === false) {
        //   return "Invalid Username and Password"
        // }
  } catch (error) {
      console.log(error);
  // return "Invalid Username and Password"
  if (error.response) {
    
    if (error.response.status === 401) {
      
      console.log(error.response.status);
      return {success: false, message: "Invalid credentials", data: null}
    }
  }else{
    return {success:false, message: "Network Error", data: null}
  }
  }
}