
// import './App.css';
import {
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Badge,
  InputGroup,
  InputLeftElement,
  Input,
  FormControl,
  Spinner,
  Button,
  Text,
  Alert,
  AlertIcon,
  AlertDescription
} from '@chakra-ui/react'

import Dashboard from './pages/Dashboard';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Link,
} from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
// import FormCustomer from './pages/FormCustomer';
import PasswordSet from './components/login/PasswordSet';
import ForgotPassword from './components/login/ForgotPassword';
import EmailSuccess from './components/login/EmailSuccess';
import EmailVerify from './components/login/EmailVerify';


import SuperData from './components/stats/SuperData';

import React,{ useEffect, useState } from 'react';
import {useAuth } from './context/AuthProvider';
import jwt from 'jwt-decode';
import ReceivedPayment from './components/payments/ReceivedPayment';
import AddLoanProvider from './components/loanProvider/AddLoanProvider';
import Borrower from './components/borrowers/Borrower';
import ViewLoan from './components/borrowers/ViewLoan';
import ViewLoanProvider from './components/loanProvider/ViewLoanProvider';
import ViewProvider from './components/loanProvider/loanProviderView/ViewProvider';
import AddBorrowers from './components/borrowers/AddBorrowers';
import Error404 from './components/login/Error404';
import IntUser from './components/stats/IntUser';
import IntAdmin from './components/stats/IntAdmin';
import ExUser from './components/stats/ExUser';
import ExtAdmin from './components/stats/ExtAdmin';
import AddLoanProduct from './components/loanProvider/AddLoanProduct';
import CreateLoanProduct from './components/loanProvider/CreateLoanProduct';
import Users from './components/loanProvider/Users';
import AllBorrowers from './components/borrowers/AllBorrowers';
import TokenExpirationHandler from './components/loanProvider/loanProviderView/TokenExpirationHandler';


function App() {
  const [open,setOpen] = useState(false)
  const cancelRef = React.useRef()

  
// const isAuthenticated = async () => {
//   const tken = localStorage.getItem("token");
//   if(tken) {
//     const { exp } = jwt(tken)
//     if(Date.now() <= exp * 1000) {
//       console.log(exp)
//       return true
//     } else {
//       return false
//     }
//   }
// }

// useEffect (()=>{
//   const checkTokenValidity =  () => {
//   const tken = localStorage.getItem("token");
//   const valid =  isAuthenticated();
//   if (!valid) {
//     localStorage.clear();
//     setOpen(true)
//     console.log('Token is invalid');
//    }  else if ( tken && tken.length > 0) {
//     // console.log('Token is valid');

//   }
 
// };


// const interval = setInterval(checkTokenValidity, 500);

// return () => {
 
//   clearInterval(interval);
 
// };
// }, []);

 

  return (
    <div className="App">

<AlertDialog
        isOpen={open}
        closeOnOverlayClick={false}
        onClose={()=>{setOpen(false)}}
      >
        <AlertDialogOverlay
        bg={'blackAlpha.300'}
        backdropFilter={'blur(10px) hue-rotate(90deg)'}
        >
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            <Alert status='warning'>
           <AlertIcon />
           Attention:
            </Alert>
            </AlertDialogHeader>

            <AlertDialogBody>
            <Text>

           Your session has expired. Please log in again to continue
            </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={()=>window.location.href = "/"}  colorScheme='yellow'  ml={3}>
               Login
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
          </AlertDialogOverlay>
      </AlertDialog>

      {/* <Dashboard /> */}
      <BrowserRouter>
      <TokenExpirationHandler />
      <Routes>
        <Route path="admin" element={<Dashboard/>}>
          <Route path="/admin/home" element={<Home/>} />

    

{/* mobi loan */}
{/* <Route path='/admin/super-home' element = {<SuperData /> } /> */}
<Route path='/admin/user.dashboard' element={<IntUser />} />
<Route path='/admin/admin.dashboard' element={<IntAdmin />} />
<Route path = '/admin/payments' element = {<ReceivedPayment />} />
<Route path='/admin/loan-provider' element = {<AddLoanProvider />} />
<Route path = '/admin/borrowers' element = {<Borrower />} />
<Route path='/admin/borrower-list' element={<AllBorrowers />} />
<Route path='/admin/view-loans' element ={<ViewLoan />} />
<Route path = '/admin/view-provider' element= {<ViewLoanProvider />} />
<Route path='/admin/view-loan-provider' element={<ViewProvider />} />
<Route path='/admin/users' element={<Users />} />
        </Route>

<Route path="user" element={<Dashboard/>}>
        <Route path="/user/exuser.dashboard" element={<ExUser/>} />
        <Route path='/user/exadmin.dashboard' element={<ExtAdmin />} />
        <Route path='/user/add-loan-products' element={<CreateLoanProduct />} />
        <Route path='/user/users' element={<Users />} />
        <Route path='/user/view-borrowers-loans' element={<AddBorrowers />} />
  </Route>

        <Route exact path='/' element={<Login/> } />
        {/* <Route path='/signingup' element={<SigningUp/> } /> */}
        <Route path='/activate' element={<PasswordSet/>} />
        <Route path = '/forgot-password' element={<ForgotPassword/>}/>
        <Route path ='/email-success/:workemail' element={<EmailSuccess/>} />
        <Route path = '/verify-email' element={<EmailVerify/>}/>
        <Route path='/error404' element={<Error404 />} />
        {/* <Route path='/verify-email' element={<} */}

       
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
